import { useContext } from "react"
import { GlobalContext } from "../../Contexts/GlobalConext"
import { Button } from "react-bootstrap"

import "./translate.css"
const Translate = () => {
    const { i18nChangeLanguage, i18nCurrentLanguage } = useContext(GlobalContext)
    return (
        <div className="d-flex justify-content-end">
            <div class="text-end mt-2 text-lang-postion container-lang-pc">
                <span type="button"
                    class={i18nCurrentLanguage == 'th' ? "fw-bold underline text-danger" : "fw-bold"}
                    onClick={() => i18nChangeLanguage('th')}>
                    ภาษาไทย</span>
                <span>
                    {' | '}
                </span>
                <span type="button"
                    class={i18nCurrentLanguage == 'en' ? "fw-bold underline text-danger" : "fw-bold"}
                    onClick={() => i18nChangeLanguage('en')}>
                    English
                </span>
            </div>
            <div className="container-lang-mobile">
                <Button className="bg-white" onClick={() => i18nCurrentLanguage === 'en' ? i18nChangeLanguage('th') : i18nChangeLanguage('en')
                }>
                    {
                        i18nCurrentLanguage !== 'en' ? <span className="fs-16 mg-n-5 fw-bold">En</span> : <span className="fs-16 mg-n-5 fw-bold">ไทย</span>
                    }
                </Button>
            </div>
        </div>
    )
}

export default Translate