import { createContext } from "react";
import { toast } from "react-toastify";
import { css } from "glamor";

export const ToastManagementContext = createContext()

export const ToastManagementProvider = ({ children }) => {
    const notifySuccess = (text) =>
    toast.success(text ?? "กรอกข้อมูลสำเร็จ !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifyError = (text) => {
    toast.error(text ?? "Wow serious error!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  const notifyWarn = (text) => {
    toast.warn(text ?? "กรุณากรอกข้อมูลให้ครบถ้วนหรือกดยอมรับเงื่อนไขก่อนค่ะ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#f8ac59 !important",
      })
    });
  };

    return (
        <ToastManagementContext.Provider value={{
            notifyWarn,
            notifyError,
            notifySuccess
        }}>
            {children}
        </ToastManagementContext.Provider>
    )
}