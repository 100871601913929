import { serviceUser } from "../Base"

export const verifyImportEmployee = async (body) => {
    const data = await serviceUser({
        method: 'post',
        url: `/auth/verify-import`,
        data: body
    })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            return err.response
        })
    return data
}

export const importEmployees = async (body) => {
    const data = await serviceUser({
        method: 'post',
        url: `/auth/import-employee`,
        data: body
    })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            return null
        })
    return data
}