// YourComponent.js
import React from "react";
import { useLanguage } from "./useLanguage";

const YourComponent = () => {
  const { language, changeLanguage, translations } = useLanguage();

  return (
    <div>
      {/* <h1>{translations.greeting}</h1>
      <p>{translations.farewell}</p>
      <button onClick={() => changeLanguage(language === "en" ? "es" : "en")}>
        Toggle Language
      </button> */}
    </div>
  );
};

export default YourComponent;
