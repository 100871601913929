import "./parking-detail.css"
import { GlobalContext } from "../../../Contexts/GlobalConext"
import { useContext } from "react"
import moment from "moment"
const ParkingDetail = () => {
    const { reserve } = useContext(GlobalContext)
    return (
        <div className="container-detail-parking d-flex flex-column">
            <div className="container-detail row align-items-end">
                <div className="col-2 col-xl-4 mb-2 px-2">
                    <div className="d-flex flex-column align-items-start">
                        <div className="d-flex align-items-end date">
                            <h2 className="fw-bold">
                                {reserve.reserveDate && moment(reserve.reserveDate).format("DD")}
                            </h2>
                            &nbsp;
                            <h5 className="fw-bold">
                                {reserve.reserveDate && moment(reserve.reserveDate).format("MMM YYYY")}
                            </h5>
                        </div>
                        <div className="d-flex d-flex align-items-center">
                            <svg width="20" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.95189 15.7931H1.98874C1.44422 15.7931 1 15.335 1 14.7734V12.6453C1 10.3251 2.83419 8.4335 5.08393 8.4335H24.9017C27.1515 8.4335 28.9857 10.3251 28.9857 12.6453L29 12.6158V14.4778C29 15.1872 28.4411 15.7635 27.7533 15.7635H25.5036C25.5466 15.5271 25.5752 15.2759 25.5752 15.0246C25.5752 12.8227 23.827 11.0345 21.6776 11.0788C19.8434 11.1084 18.2671 12.5271 17.9662 14.4039C17.8802 14.8768 17.8946 15.3498 17.9806 15.7783H11.4749C11.5179 15.5419 11.5466 15.2906 11.5466 15.0394C11.5466 12.8374 9.79836 11.0493 7.64892 11.0936C5.81474 11.1232 4.23848 12.5419 3.93756 14.4187C3.85159 14.8916 3.86592 15.3645 3.95189 15.7931ZM3.95189 15.7931C3.9089 15.5566 3.88025 15.3054 3.88025 15.0542C3.88025 12.8818 5.5998 11.1084 7.70624 11.1084C9.81269 11.1084 11.5322 12.867 11.5322 15.0542C11.5322 15.3054 11.5036 15.5566 11.4606 15.7931M3.93756 15.7931C4.28147 17.6256 5.82907 19 7.70624 19C9.58342 19 11.131 17.6256 11.4749 15.7931M17.9662 15.7931C18.3101 17.6256 19.8577 19 21.7349 19C23.6121 19 25.1597 17.6256 25.5036 15.7931C25.5466 15.5566 25.5752 15.3054 25.5752 15.0542C25.5752 12.867 23.8557 11.1084 21.7492 11.1084C19.6428 11.1084 17.9232 12.8818 17.9232 15.0542C17.9232 15.3054 17.9519 15.5566 17.9949 15.7931M15 1V8.38916M13.1372 1H16.8485C20.8321 1 24.0563 4.32512 24.0563 8.4335H5.92938C5.92938 4.32512 9.15353 1 13.1372 1Z" stroke="#E53535" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <div>
                                &nbsp;
                                {reserve?.licensePlate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-xl-4 mb-2 px-0 d-flex align-items-center px-2">
                    <span className="pb-0 building d-flex justify-content-center align-items-center">
                        <svg width="15" height="15" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.64144 19C7.64144 19 1 13.4733 1 8.26719C1 3.06108 4.32072 1 7.74361 1C11.1793 1 14.5 3.07635 14.5 8.26719C14.5 13.458 7.64144 19 7.64144 19Z" stroke="#E53535" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.98116 10.4607C9.35147 10.4607 10.4623 9.35023 10.4623 7.98037C10.4623 6.6105 9.35147 5.5 7.98116 5.5C6.61086 5.5 5.5 6.6105 5.5 7.98037C5.5 9.35023 6.61086 10.4607 7.98116 10.4607Z" fill="#E53535" />
                        </svg>
                        &nbsp;
                        &nbsp;
                        {reserve.building}
                    </span>
                </div>
                <div className="col-4 ms-auto col-xl-4 mb-2 d-flex flex-column align-items-end text-start px-2">
                    <h5 className="fw-bold w-100 text-end">
                        {<span>{reserve.floor}<sup>{reserve.floor === 1 ? 'st' : 'nd'}</sup> </span>}
                    </h5>
                    <div>
                        <p className="p-0 m-0 d-flex align-items-center">
                            <span className="text-parking-detail ">
                                <svg width="13" height="13" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.0029 19C14.9751 19 19.0058 14.9706 19.0058 10C19.0058 5.02945 14.9751 1 10.0029 1C5.03074 1 1 5.02945 1 10C1 14.9706 5.03074 19 10.0029 19Z" stroke="#E53535" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9.47656 6.99219V11.3645H13.8844" stroke="#E53535" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                &nbsp;
                                &nbsp;
                                Open {reserve.startTimePicker} - {reserve.endTimePicker}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ParkingDetail