import { Button, Row, Col, Spinner } from "react-bootstrap";
import { GlobalContext } from "../../Contexts/GlobalConext"
import { ToastManagementContext } from "../../Contexts/ToastManagementContext"
import { decodedVipCodeService, VerifyVipCodeService } from "../../Services/Auth"
import { getFreeParking } from "../../Services/ReserveParking/ReserveListService"
import TextField from "../Form/TextField";
import { RegisterValidator } from "../../Validator"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import React, { useState, useEffect, useContext } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import ButtonPic from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconUser from "../image/ic-user.png";
import Footer from "../Footer/Footer";
import FileUpload from "../Form/FileUpload";
import TimeField from "../Form/TimeField";
import { Link, useLocation } from "react-router-dom";
import ParkingTopPanel from "../Parking/ParkingTopPanel/ParkingTopPanel"
import ChooseStep from "../Step/Step";
import { generateTimeList, generateEndTimeList } from "../../utils/Constant"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

function Regis_form() {
  const { t } = useTranslation()
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get("uuid");
  const { handleChangeReserve, reserve, setStep, initialForm } = useContext(GlobalContext)
  const { notifySuccess, notifyWarn } = useContext(ToastManagementContext)
  const [isValid, setValid] = useState(false)
  // datepicker
  const [file, setFile] = useState(null);
  const handleChange = (event) => {
    try {
      if (event.target) {
        setFile(URL.createObjectURL(event.target.files[0]));
        handleChangeReserve({
          ...reserve,
          avatar: event.target.files
        })
      } else if (event.length > 0) {
        setFile(URL.createObjectURL(event[0]));
      } else {
        setFile(reserve.avatarAPI)
      }
    } catch (error) {

    }
  };

  const classes = useStyles();

  const handleSelectedFloor = (e) => {
    handleChangeReserve({
      ...reserve,
      floor: e
    })
  };

  //   ClearData
  const ClearData = () => {
    handleChangeReserve(initialForm)
  };

  const fetchVipcode = async () => {
    const response = await decodedVipCodeService(param)
    console.log(response)
    if(response) {
      console.log(response.data)
      await handleChangeReserve({
        ...reserve,
        vipCode: response.data
      })
      verify(response.data)
    }
  }

  const verify = async (code) => {
    const response = await VerifyVipCodeService(code || reserve.vipCode)
    if (response) {
      setFile(response.data.avatar)
      handleChangeReserve({
        ...reserve,
        avatarAPI: response.data.avatar,
        reserveDate: response.info.date,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        telephone: response.data.telephone,
        vipCode: response.data.vip_code,
        email: response.data.email,
        vipCodeCheck: response.data.vip_code,
        startTimePicker: response.info.start_time,
        endTimePicker: response.info.end_time,
        building: response.info.location
      })
      notifySuccess('พบข้อมูล VIP CODE')
    } else {
      notifyWarn('ไม่พบข้อมูล VIP CODE')
      ClearData()
    }
  }

  useEffect(() => {

    const validate = RegisterValidator(
      reserve.firstName,
      reserve.lastName,
      reserve.telephone,
      reserve.licensePlate,
      moment(reserve.reserveDate).format("YYYY-MM-DD"),
      reserve.reserveStartTime,
      reserve.reserveEndTime,
      reserve.floor,
      reserve.email
    )
    setValid(validate)
    console.log(validate)
  }, [reserve])

  useEffect(() => {
    fetchVipcode()
    setStep(1)
    if (reserve?.avatar) {
      handleChange(reserve?.avatar)
    }
  }, [])

  const startTimeList = generateTimeList(reserve.startTimePicker, reserve.endTimePicker).map((item) => {
    return {
      label: item,
      value: item
    }
  })

  const endTimeList = generateEndTimeList(reserve.reserveStartTime, reserve.endTimePicker).map((item) => {
    return {
      label: item,
      value: item
    }
  })

  return (
    <Col xs="12" lg="8" className="text-center align-self-center position-relative overflow-hidden">
      <ToastContainer />
      <div className="mb-5">
        <ParkingTopPanel building={reserve?.building} />
      </div>
      <Row className="justify-content-center">
        <Col xs="12" lg="10" className="bg-white p-4 radius-35">
          <Row>
            <Col xs="12" lg="8" className="mx-auto">
              <h3 class="pt-2">{t('info')} </h3>
              <ChooseStep
                stepLists={[1, 2, 3, 4]}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="12" lg="3" className="mx-auto d-flex justify-content-center">
              {/* <UploadPicture /> */}
              <Grid className="upload-profile">
                <Grid>
                  {/* logo */}
                  {(() => {
                    if (!file) {
                      return (
                        <img
                          src={IconUser}
                          width="120px"
                          height="auto"
                          alt="IconUser"
                        />
                      );
                    } else {
                      return (
                        <img
                          className="rounded-circle"
                          src={file}
                          width="50%"
                          height="auto"
                          alt="imguser"
                        />
                      );
                    }
                  })()}

                  <p />
                  <FileUpload
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChanged={handleChange}
                  />

                  <label htmlFor="contained-button-file">
                    {/* color="primary" */}
                    <ButtonPic
                      variant="contained"
                      component="span"
                      float="center"
                      className="btn-upload py-1 px-2"
                    >
                      {t('click_upload')}
                    </ButtonPic>
                  </label>
                </Grid>
              </Grid>
            </Col>
            <Col xs="12" lg="9" className="text-start">
              {" "}
              <Row className="justify-content-center text-start">
                <Col xs="12" lg="12">
                  <p className="fw-bold mb-0">
                    {t('vip_code_parking')}
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center text-start mb-2">
                <Col xs="7" lg="9">
                  <TextField name="dataCode"
                    placeholder={t('forms.parking.placeholder_vipcode')}
                    className="form-control position-input-code"
                    onKeyPressValidate={(text) => !/[0-9]/.test(text)}
                    onChanged={(text) =>
                      handleChangeReserve({
                        ...reserve, vipCode: text
                      })
                    }
                    value={reserve.vipCode}
                  />
                </Col>
                <Col xs="5" lg="3" className="">
                  <Button className="btn-danger bg-red-light px-4 btn-danger-size white-sp w-100"
                    onClick={() => verify()}
                  >
                    {t('confirm')}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="6" className="mt-3">
                  <Row className="justify-content-center text-start align-items-center">
                    <Col xs="12" lg="12">
                      <p className="fw-bold mb-0">
                        {t('first_name_parking')}<span className="text-danger ">*</span>
                      </p>
                    </Col>
                    <Col xs="12" lg="12">
                      <TextField name="dataName"
                        value={reserve.firstName}
                        disabled={!reserve.vipCodeCheck}
                        onChanged={(text) =>
                          handleChangeReserve({
                            ...reserve,
                            firstName: text

                          })
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="6" className=" mt-3">
                  <Row className="justify-content-center text-start mb-2 align-items-center">
                    <Col xs="12" lg="12">
                      <p className="fw-bold mb-0">
                        {t('last_name_parking')}<span className="text-danger ">*</span>
                      </p>
                    </Col>
                    <Col xs="12" lg="12">
                      <TextField name="dataSurName"
                        disabled={!reserve.vipCodeCheck}
                        value={reserve.lastName}
                        onChanged={(text) =>
                          handleChangeReserve({
                            ...reserve,
                            lastName: text
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-center text-start">
                <Col xs="12" lg="6" className="mt-3">
                  <Row className="d-flex mb-2 align-items-center">
                    <Col xs="12" lg="12">
                      <p className="fw-bold mb-0 white-sp">
                        {t('phone_parking')}<span className="text-danger ">*</span>
                      </p>
                    </Col>
                    <Col xs="12" lg="12">
                      <TextField name="dataTel"
                        value={reserve.telephone}
                        disabled={!reserve.vipCodeCheck}
                        maxLength={10}
                        onChanged={(text) => handleChangeReserve({
                          ...reserve,
                          telephone: text
                        })}
                        onKeyPressValidate={(text) => !/[0-9]/.test(text)}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col xs="12" lg="6" className="mt-3">
                  <Row className="d-flex align-items-center mb-2">
                    <Col xs="12" lg="12">
                      <p className="fw-bold mb-0">
                        {t('email_parking')}<span className="text-danger ">*</span>
                      </p>
                    </Col>
                    <Col xs="12" lg="12">
                      <TextField name="dataTel"
                        disabled={!reserve.vipCodeCheck}
                        value={reserve.email}
                        onChanged={(text) => handleChangeReserve({
                          ...reserve,
                          email: text
                        })}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>


              <Row className="justify-content-center text-start mb-2 d-flex align-items-center mt-3">
                <Col xs="12" lg="12" className="white-sp">
                  <Col>
                    <p className="fw-bold mb-0"> {t('license_plate_parking')}<span className="text-danger ">*</span></p>
                  </Col>
                </Col>
                <Col xs="12" lg="12" className="">
                  <TextField
                    name="dataLicense"
                    placeholder={t('forms.parking.placeholder_license_plate')}
                    disabled={!reserve.vipCodeCheck}
                    value={reserve.licensePlate}
                    onKeyPressValidate={(text) => !/[A-Za-zก-๙0-9]/.test(text)}
                    onChanged={(text) => handleChangeReserve({
                      ...reserve,
                      licensePlate: text
                    })}
                  />
                </Col>
              </Row>

              <Row className="justify-content-center text-start mb-2 d-flex align-items-center">
                <Col xs="12" lg="5" className="mt-3">
                  <Row className="d-flex align-items-center">
                    <Col xs="12" lg="12" className="white-sp">
                      <p className="fw-bold mb-0">{t('date_parking')}</p>
                    </Col>
                    <Col xs="12" lg="12" className="">
                      <TextField
                        name="dataLicense"
                        disabled={true}
                        value={reserve.reserveDate ? moment(reserve.reserveDate).format("DD/MM/YYYY") : ""}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col xs="12" lg="3" className="mt-3">
                  <Col>
                    <p className="fw-bold mb-0">{t('start_time_parking')}</p>
                  </Col>
                  <TimeField
                    name="reserveStartTime"
                    options={startTimeList ?? []}
                    placeholder={t('forms.parking.select')}
                    disabled={reserve.vipCodeCheck}
                    onChanged={(date) => {
                      handleChangeReserve({ ...reserve, reserveStartTime: date, reserveEndTime: '' })
                    }
                    }
                    value={reserve.reserveStartTime}
                  />
                </Col>

                <Col xs="12" lg="1" className="d-none d-md-flex h-100 align-self-center mt-3  justify-content-center">
                  {' to '}
                </Col>

                <Col xs="12" lg="3" className="mt-3">
                  <Col>
                    <p className="fw-bold mb-0">{t('end_time_parking')}</p>
                  </Col>
                  <TimeField
                    name="reserveEndTime"
                    options={
                      reserve.reserveStartTime ? endTimeList : []
                    }
                    placeholder={t('forms.parking.select')}
                    disabled={reserve.vipCodeCheck}
                    onChanged={(date) => handleChangeReserve({ ...reserve, reserveEndTime: date })}
                    value={reserve.reserveEndTime}
                  />
                </Col>
              </Row>

              <Col xs="12" lg="12">
                <p className="text-danger">
                  <i>
                    * {t('time_warn')}
                  </i>
                </p>
              </Col>
              <Row className="justify-content-center text-start">
                <Col xs="12" lg="12">
                  <Row className="d-flex align-items-center">
                    <Col xs="12" lg="12">
                      <p className="fw-bold mb-0">{t('building_parking')}</p>
                    </Col>
                    <Col xs="12" lg="12">
                      <TextField value={reserve.building} disabled={true} />
                    </Col>
                  </Row>
                </Col>

                <Col xs="12" lg="12" className="customize-select mt-3">
                  <Col>
                    <p className="fw-bold mb-0">{t('floor_parking')}
                      <span className="text-danger ">*</span></p>
                  </Col>
                  {
                    !reserve.vipCodeCheck ?
                      <TextField
                        placeholder={t('forms.parking.placeholder_floor')}
                        disabled /> :
                      <Select
                        className="w-100"
                        classNamePrefix={'parking'}
                        isSearchable={false}
                        isDisabled={!reserve.vipCodeCheck}
                        onChange={(e) => handleSelectedFloor(e.value)}
                        placeholder={t('forms.parking.placeholder_floor')}
                        defaultValue={
                          [
                            {
                              label: t("floor", { floor: 1 }),
                              value: 1
                            },
                            {
                              label: t("floor", { floor: 2 }),
                              value: 2
                            },
                          ].find(item => item.value === reserve.floor)
                        }
                        options={
                          [
                            {
                              label: t("floor", { floor: 1 }),
                              value: 1
                            },
                            {
                              label: t("floor", { floor: 2 }),
                              value: 2
                            },
                          ]
                        }
                      >
                        {" "}
                      </Select>
                  }
                </Col>
              </Row>
              <Row className="justify-content-center my-4">
                <Col xs="6" lg="auto" className="d-flex justify-content-center">
                  <Button
                    className="mt-2 btn-outline-danger btn-out-danger px-5"
                    onClick={() => ClearData()}
                  >
                    {t('cancel')}
                  </Button>
                </Col>
                <Col xs="6" lg="auto" className="d-flex justify-content-center">
                  <Link className={`mt-2 btn btn-danger px-5 ${isValid === false ? 'a-disabled' : ''}`} to={'/parking/select-lot'}>{t('next')}</Link>
                </Col>
              </Row>
            </Col>
          </Row>
          {/*  footer */}
        </Col>
        <Row className="justify-content-center mt-3">
          <Footer />
        </Row>
      </Row >
    </Col >
  );
}

export default Regis_form;
