import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLanguage } from "../translations/useLanguage";

const CustomModal = ({ showModal, handleClose, modalTitle, modalContent }) => {
  const { language, changeLanguage, translations } = useLanguage();
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalContent}</Modal.Body>
      <Modal.Footer>
        <Button className="btn-close-modal fw-bold" onClick={handleClose}>
          {language === "en"
            ? translations.text_close_EN
            : translations.text_close_TH}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const App = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleOpen = (data) => {
    setShowModal(data);
  };
  const handleClose = () => {
    setShowModal(false);
    props.setDataModal(false);
  };
  return (
    <div>
      {/* {isLoading ? <Loading /> : ""} */}
      <CustomModal
        showModal={props.status}
        handleClose={handleClose}
        modalTitle=<h5
          className="line-break-text underline text-center fw-bold"
          dangerouslySetInnerHTML={{
            __html: props.dataDetail.header,
          }}
        />
        modalContent=<div
          className="line-break-text"
          dangerouslySetInnerHTML={{
            __html: props.dataDetail.data,
          }}
        />
      />
    </div>
  );
};

export default App;
