
import {
    serviceUser,
} from "../Base"

export const LoginService = async (uesrname, password) => {
    const data = await serviceUser({
        method: 'post',
        url: 'auth/login',
        data: {
            username: uesrname,
            password: password
        }
    }).then((response) => {
        return response.data
    }).catch(err => {
        return null
    })
    return data
}