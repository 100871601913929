import React, { useEffect, useState } from "react";

const linkImg = "https://tsmartoffice.verkoffice.com/testdrive/qrcode/103";

const MyComponent = (data) => {
  //   console.log("data img : ", data.dataImg);
  const [imageDataUrl, setImageDataUrl] = useState("");

  useEffect(() => {
    let getDataImg = data.dataImg;
    console.log("linkImg : ", typeof linkImg);
    console.log("data.dataImg : ", typeof data.dataImg);
    // "https://k8s-tsmartoffice.verkoffice.com/testdrive/qrcode/203";
    const loadImageDataUrl = async () => {
      try {
        // const response = await fetch(data.dataImg);
        const response = await fetch(await data.dataImg);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageDataUrl(reader.result);
       
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImageDataUrl();
  }, []);

  return (
    <div>
      {/* Assuming you want to display the image within a div */}
      <img src={imageDataUrl} alt="ImageQr" width={"180px"} />
      {/* <img
  
        src={data.dataImg}
        alt="ImageQr"
        width={"140px"}
      /> */}
    </div>
  );
};

export default MyComponent;
