import { regexExpression } from "./Constants"

export const RegisterValidator = (
    dataName,
    dataSurName,
    dataTel,
    dataLicense,
    dataDate,
    dataTimestart,
    dataTimeend,
    valueFloor,
    dataEmail
) => {
    const validTel = regexExpression.telephone.test(dataTel)
    const validEmail = regexExpression.email.test(dataEmail)
    
    if (
        !dataName ||
        !dataSurName ||
        !validTel ||
        !dataLicense ||
        !valueFloor ||
        !dataDate ||
        !dataTimestart ||
        !dataTimeend ||
        !validEmail
    ) {
        return false
    } else {
        return true
    }
}