import { useState, createContext } from "react";

export const UserData = createContext();
export const UserContextProvider = ({ children }) => {
  const [dataUser, setDataUser] = useState({
    booking_type: "walkin",
    testdrive_date: "",
    reserve_type: "",
    car_id1: "",
    car_id2: "",
    testdrive_type: "",
    fullname: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    dateofbirth: "",
    gender: "",
    testdrive_time: "",
    permission_tmt: false,
    permission_dealer: false,
    file: "",
  });

  return (
    <UserData.Provider
      value={{
        dataUser,
        setDataUser,
      }}
    >
      {children}
    </UserData.Provider>
  );
};
