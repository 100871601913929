// carCommercial
import commercial1 from "../img-testDrive/imgCar/commercial1.webp";
import commercial2 from "../img-testDrive/imgCar/commercial2.webp";
import commercial3 from "../img-testDrive/imgCar/commercial3.webp";
import commercial4 from "../img-testDrive/imgCar/commercial4.webp";
// carSedanHatchback
import SedanHatchback1 from "../img-testDrive/imgCar/SedanHatchback1.webp";
import SedanHatchback2 from "../img-testDrive/imgCar/SedanHatchback2.jpg";
import SedanHatchback3 from "../img-testDrive/imgCar/SedanHatchback3.png";
import SedanHatchback4 from "../img-testDrive/imgCar/SedanHatchback4.webp";
import SedanHatchback5 from "../img-testDrive/imgCar/SedanHatchback5.webp";
import SedanHatchback6 from "../img-testDrive/imgCar/SedanHatchback6.webp";
import SedanHatchback7 from "../img-testDrive/imgCar/SedanHatchback7.webp";
// SUV & PPV
import suv1 from "../img-testDrive/imgCar/suv1.webp";
import suv2 from "../img-testDrive/imgCar/suv2.png";
import suv3 from "../img-testDrive/imgCar/suv3.png";
import suv4 from "../img-testDrive/imgCar/suv4.webp";
import suv5 from "../img-testDrive/imgCar/suv5.webp";
const dataCar = {
  carCommercial: [
    {
      src: commercial1,
      text: "Hilux Revo C cab",
      grade: ["Preruner 2.4 Entry", "Preruner 2.4 mid", "2.8 High 4x4"],
      type: "Commercial",
    },
    {
      src: commercial2,
      text: "Hilux Revo D cab",
      grade: ["Preruner 2.4 High", "Z Edition 2.4 mid", " 2.8 High 4x4"],
      type: "Commercial",
    },
    {
      src: commercial3,
      text: "Hilux Revo D cab",
      grade: ["D Cab 2.8 "],
      type: "Commercial",
    },
    {
      src: commercial4,
      text: "Hilux Revo GR-S",
      grade: ["D Cab 2.8 Hi Perf", "D Cab 2.8 Z Edition"],
      type: "Commercial",
    },
  ],
  // SUV & PPV
  carSUV: [
    {
      src: suv1,
      text: "Fortuner Legender",
      grade: ["2.4 4WD"],
      type: "SUV & PPV",
    },
    {
      src: suv2,
      text: "Fortuner GR-S",
      grade: ["2.8 Hi Perf"],
      type: "SUV & PPV",
    },
    {
      src: suv3,
      text: "Fortuner",
      grade: ["2.4 V 2WD"],
      type: "SUV & PPV",
    },
    {
      src: suv5,
      text: "Sienta IMP 2022",
      grade: ["1.5 V"],
      type: "SUV & PPV",
    },
    {
      src: SedanHatchback1,
      text: "COROLLA CROSS",
      grade: ["HEV GR Sport", "HEV Premium Safety", "1.8 Sport"],
      type: "Sedan & Hatchback",
    },
  ],
  carSedanHatchback: [
    {
      src: SedanHatchback2,
      text: "ATIV",
      grade: ["sport premium"],
      type: "Sedan & Hatchback",
    },
    {
      src: SedanHatchback3,
      text: "YARIS",
      grade: ["sport premium X"],
      type: "Sedan & Hatchback",
    },
    {
      src: SedanHatchback4,
      text: "Camry MC 2021",
      grade: ["2.5 HEV Premium Luxury", "2.5 Premium", "2.5 Sport"],
      type: "Sedan & Hatchback",
    },
    {
      src: SedanHatchback5,
      text: "Corolla Altis  2022",
      grade: ["HEV GR-S", "GR-S", "HEV Premium", "1.8 Sports", "1.6 G"],
      type: "Sedan & Hatchback",
    },
    // {
    //   src: SedanHatchback6,
    //   text: "CHR GR 2022",
    //   grade: ["GR Sport"],
    //   type: "Sedan & Hatchback",
    // },
    // {
    //   src: SedanHatchback7,
    //   text: "CHR IMP 2021",
    //   grade: ["HV Premium Safety"],
    //   type: "Sedan & Hatchback",
    // },
  ],
  // PMV
  carPMV: [
    {
      src: suv4,
      text: "VELOZ",
      grade: ["1.5 premium", "1.5 Smart"],
      type: "SUV & PPV",
    },
    // {
    //   src: suv4,
    //   text: "VELOZ",
    //   grade: ["1.5 premium", "1.5 Smart"],
    //   type: "SUV & PPV",
    // },
    // {
    //   src: suv4,
    //   text: "VELOZ",
    //   grade: ["1.5 premium", "1.5 Smart"],
    //   type: "SUV & PPV",
    // },
    // {
    //   src: suv4,
    //   text: "VELOZ",
    //   grade: ["1.5 premium", "1.5 Smart"],
    //   type: "SUV & PPV",
    // },
  ],
};

export default dataCar;
