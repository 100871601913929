import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL || "http://localhost:3000/";

export const serviceUser = axios.create({
  // baseURL: `http://localhost:3001`,
  baseURL: `${baseURL}/user`,
});

export const serviceReserveParking = axios.create({
  // baseURL: `http://localhost:3003/reserve`,
  baseURL: `${baseURL}/parking/reserve`,
});

export const serviceTestDrive = axios.create({
  baseURL: `${baseURL}`,
});
