/* eslint-disable react-hooks/rules-of-hooks */
import { useState, } from "react";
import Swal from "sweetalert2";
import { verifyImportEmployee, importEmployees } from "../Services/Auth"

export const useImportDataHelpers = () => {
    // const navigate = useNavigate();

    // function navigateToBack(page) {
    //     navigate(page);
    // }
    const [importData, setImportData] = useState([]);
    const [isVerify, setIsVerify] = useState(true);
    const [verifySuccess, setVerifySuccess] = useState(0);
    const [verifySkip, setVerifySkip] = useState(0);
    const [verifyNew, setVerifyNew] = useState(0);
    const [totalRow, setTotalRow] = useState(0);
    const [failedRow, setFailedRow] = useState([]);

    const handleImport = async (rows) => {
        setVerifySuccess(0);
        setVerifySkip(0);
        setVerifyNew(0);
        setTotalRow(rows.length - 1);
        const row = [];
        rows.slice(2).map((item, index) => {
            if (item.length > 0) {
                row.push({
                    key: index,
                    COM_DESC: item[0],
                    COM_ABB: item[1],
                    EMP_CODE: item[2],
                    EMP_FNAME: item[3],
                    EMP_LNAME: item[4],
                    EMAIL: item[5],
                    DESKPHONENO: item[6],
                    DIRECT: item[7],
                    MOBILE: item[8],
                    USER_LOGON_NAME: item[9],
                    DIV_DESC: item[10],
                    DIV_ABB_NAME: item[11],
                    SUB_DIV_DESC: item[12],
                    SUB_DIV_ABB_NAME: item[13],
                    DEPT_DESC: item[14],
                    DEPT_ABB_NAME: item[15],
                    SECT_DESC: item[16],
                    SECT_ABB_NAME: item[17],
                    LINE_DESC: item[18],
                    LINE_ABB_NAME: item[19],
                    JOB_DESC: item[20],
                    EMP_GRADE: item[21],
                    GRADE_LEVEL: item[22],
                    NATIONALITY: item[23],
                    LOCATION_DESC: item[24],
                    BEGIN_DATE: item[25],
                    ID_CARD: item[26],
                    COSTCENTER: item[27],
                    EMP_TYPE: item[28],
                    GENDER: item[29],
                    DATE_OF_BIRTH: item[30]
                });
            }
        });
        await setImportData(row);
    };

    const verifyData = async () => {
        console.log('importData ', importData)
        try {
            setVerifySuccess(0);
            setVerifySkip(0);
            setVerifyNew(0);
            setFailedRow([]);
            let create = 0;
            let success = 0;
            let skip = 0;
            let error = 0;
            const batchSize = 10;
            const failedArr = [];
            setTotalRow(importData.length - 1);
            for (let i = 0; i < importData.length; i += batchSize) {
                const batch = importData.slice(i, i + batchSize);
                const data = batch.map((item) => ({ ...item }));
                const dataToVerify = {
                    import_data: data,
                };
                const response = await verifyImportEmployee(dataToVerify);
                if (response) {
                    console.log(response)
                    success += response.data.success;
                    skip += response.data.update;
                    create += response.data.create;

                    const failedData = response.verify_failed;
                    failedData.map((item) => {
                        failedArr.push(item);
                    });

                    setVerifySuccess(success);
                    setVerifySkip(skip);
                    setVerifyNew(create);
                } else {
                    error++;
                    Swal.fire({
                        title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
                        icon: "error",
                        confirmButtonText: "ตกลง",
                    });
                    return;
                }
            }

            setFailedRow(failedArr);
            console.log(failedArr)
            if (error === 0) {
                Swal.fire({
                    title: "ตรวจสอบข้อมูลสำเร็จ กรุณาบันทึกข้อมูลเข้าสู่ระบบ",
                    icon: "success",
                    confirmButtonText: "ตกลง",
                });
                failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);
            }
        }
        catch (error) {
            Swal.fire({
                title: "เกิดข้อผิดพลาด",
                icon: "error",
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                cancelButtonText: `ยกเลิก`,
                reverseButtons: true,
            });
        }
    };

    const importEmployee = async () => {
        try {
            setVerifySuccess(0);
            setVerifySkip(0);
            setVerifyNew(0);
            setFailedRow([]);
            let success = 0;
            let skip = 0;
            let error = 0;
            const batchSize = 10;
            setTotalRow(importData.length - 1);

            await Swal.fire({
                title: "คุณต้องการที่จะบันทึกข้อมูลใช่หรือไม่",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                cancelButtonText: "ยกเลิก",
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    for (let i = 0; i < importData.length; i += batchSize) {
                        const batch = importData.slice(i, i + batchSize);
                        const data = batch?.map((item) => ({ ...item }));
                        const dataToVerify = {
                            import_data: data,
                        };
                        const response = await importEmployees(dataToVerify);

                        if (response) {
                            success += response.data.success;
                            skip += response.data.skip;
                            setVerifySuccess(success);
                            setVerifySkip(skip);
                        } else {
                            error++;
                            Swal.fire({
                                title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
                                icon: "error",
                                confirmButtonText: "ตกลง",
                            });
                            return;
                        }
                    }
                } else {
                    return;
                }
            });

            if (error === 0) {
                Swal.fire({
                    title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
                    icon: "success",
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setIsVerify(false);
                    }
                });
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    icon: "error",
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "เกิดข้อผิดพลาด",
                icon: "error",
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                cancelButtonText: `ยกเลิก`,
                reverseButtons: true,
            })
        }
    };

    return {
        isVerify,
        verifySuccess,
        verifySkip,
        verifyNew,
        totalRow,
        failedRow,
        handleImport, verifyData, importEmployee
    }
}
