import { serviceUser } from "../Base"

export const getPdpa = async (lang) => {
    const data = await serviceUser({
        method: 'get',
        url: `/auth/terms`,
        headers: {
            language:  lang ?? 'th'
        },
        params: {
            process: 'pdpa_register'
        }
    })
        .then((response) => {
            return response.data.data
        })
        .catch((err) => {
            return null
        })
    return data
}