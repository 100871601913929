import { useTranslation } from "react-i18next"
import "./style.css"
const ParkingTopPanel = ({ system, building }) => {
    const { t } = useTranslation()
    return (
        <div className="d-flex panel">
            <h4 className="card-text fw-bold mt-4 text-start mx-auto">
                {t('heading_parking', { name:  system ?? 'Parking System' })}
                <span className="text-red-light">
                    {building ? building?.toUpperCase() : 'Toyota Building (TDEX)'}
                </span>
            </h4>
        </div>
    )
}

export default ParkingTopPanel