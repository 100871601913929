import { Row, Col, Button } from "react-bootstrap"
import TextField from "../Form/TextField"
import { useEffect, useState } from "react"
import { useImportDataHelpers } from "../../helpers"
import { LoginService, GetProfile } from "../../Services/Auth"
import ImportExcel from "../Form/ImportExcel";
import Swal from "sweetalert2";

const Admin = () => {
    const { handleImport, verifyData, failedRow, isVerify, importEmployee, importData } = useImportDataHelpers()
    const [auth, setAuth] = useState({
        username: "",
        password: "",
        loggedIn: false
    })
    const [profile, setProfile] = useState({
        username: "",
        password: "",
        loggedIn: false
    })

    const handleLogin = async () => {
        try {
            const response = await LoginService(auth.username, auth.password)
            console.log(response.status);
            if (response.status === 'success') {
                localStorage.setItem("access-token", response.data.token)
                Swal.fire({
                    title: "เข้าสู่ระบบสำเร็จ",
                    icon: "success",
                    confirmButtonText: "ตกลง",
                });
                getProfile()
            }
        } catch (error) {
            Swal.fire({
                title: "ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง",
                icon: "error",
                confirmButtonText: "ตกลง",
            });
        }
    }

    const getProfile = async () => {
        try {
            const response = await GetProfile()
            if (response.status == 'success') {
                setAuth({
                    ...auth,
                    loggedIn: true
                })
            } else {
                localStorage.removeItem("access-token")
                setAuth({
                    ...auth,
                    loggedIn: false
                })
            }
        } catch (error) {
            console.log('error')
            localStorage.removeItem("access-token")
            setAuth({
                ...auth,
                loggedIn: false
            })
        }
    }
    useEffect(() => {
        getProfile()
    }, [profile])

    return (
        <Col xs="12" md="6" className="min-vh-100 d-flex align-items-center">
            {
                !auth.loggedIn &&
                <Row className="mx-auto">
                    <Col xs="12" className="mt-3">
                        <TextField
                            value={auth.username}
                            placeholder={'username'}
                            onChanged={(e) => setAuth({
                                ...auth,
                                username: e
                            })}
                        />
                    </Col>
                    <Col xs="12" className="my-3">
                        <TextField
                            type={'password'}
                            placeholder={'password'}
                            value={auth.password}
                            onChanged={(e) => setAuth({
                                ...auth,
                                password: e
                            })}
                        />
                    </Col>
                    <Col xs="12" className="d-flex justify-content-end">
                        <Button onClick={() => handleLogin()}>Login</Button>
                    </Col>
                </Row>
            }
            {
                auth.loggedIn &&
                <Row className="d-flex justify-content-center w-100">
                    {
                        failedRow && failedRow.map((item, index) => {
                            console.log(item);
                            return (
                                <Col xs="12" key={index} className="text-danger pb-3">
                                    แถวที่ {item.key}
                                    {item.result} ไม่ถูกต้อง
                                </Col>
                            )
                        })
                    }
                    <Col xs="12">
                        <ImportExcel onImport={handleImport} />
                    </Col>
                    <Col xs="12" className="d-flex justify-content-end w-100">
                        <Row>
                            <Col xs="12" className="mt-3">
                                <button
                                    className="btn btn-primary w-100"
                                    disabled={importData && importData.length == 0 ? true : false}
                                    onClick={verifyData}
                                >
                                    ตรวจสอบไฟล์
                                </button>
                            </Col>
                            <Col xs="12" className="mt-3">
                                <button
                                    className="btn btn-primary w-100"
                                    disabled={isVerify}
                                    onClick={importEmployee}
                                >
                                    บันทึก{" "}
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        </Col >
    )
}

export default Admin