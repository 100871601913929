import "./parking-status.css"
const ParkingStatus = () => {
    const statusLists = [
        {
            element: <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                <circle cx="4" cy="4" r="3.5" stroke="black" fill="#313133" />
            </svg>,
            text: 'Available'
        },
        {
            element: <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                <circle cx="4" cy="4" r="4" fill="#C9CBD0" />
            </svg>,
            text: 'Unavailable'
        },
        {
            element:
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                    <circle cx="4" cy="4" r="4" fill="#EE9C22" />
                </svg>,
            text: 'Booked'
        },
        {
            element: <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                <circle cx="4" cy="4" r="4" fill="#8CC63F" />
            </svg>,
            text: 'Selected'
        },
    ]
    return (
        <div className="container">
            <div className="row mx-auto mt-5">
                <div className="col-12  d-flex justify-content-center">
                    {
                        statusLists.map((item, index) => {
                            return (
                                <div className="d-flex  align-items-center" key={index}>
                                    {item.element}
                                    <span className="px-2">{item.text}</span>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="col-12  d-flex justify-content-center ">
                    <div className="hr-parking"></div>
                </div>
            </div>
        </div>
    )
}

export default ParkingStatus