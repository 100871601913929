import React, { useRef } from 'react';

const TimeField = ({ name, disabled, value, options, placeholder, onChanged }) => {
  return (
    <div className={disabled ? 'time-reserve-select-enabled' : 'time-reserve-select-disabled'}>
      <select
        id={name}
        name={name}
        disabled={!disabled}
        value={value}
        onChange={(e) => onChanged(e.target.value)}
      >
        {placeholder && <option disabled selected value="">{placeholder}</option>}
        {options &&
          options.map((dt, i) => (
            <option key={i} value={dt.value}>{dt.label}</option>
          ))}
      </select>
    </div>
  );
};

export default TimeField;
