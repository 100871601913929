const TextField = ({ name, className, onChanged, type, value, onKeyPressValidate, maxLength, style, placeholder, disabled }) => {
    return (
        <input
            type={type ?? 'text'}
            style={{ style }}
            disabled={disabled}
            name={name}
            className={className ?? "form-control"}
            placeholder={placeholder}
            maxLength={maxLength}
            value={value}
            onKeyPress={(event) => {
                if (onKeyPressValidate) {
                    if (onKeyPressValidate(event.key)) {
                        event.preventDefault();
                    }
                }
            }}
            onChange={(e) => onChanged ? onChanged(e.target.value) : console.log(e)}
        />
    )
}

export default TextField