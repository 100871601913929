// import imtoyotamobile from "../image/toyota-logo.png";
import { useLocation } from "react-router-dom";
// import imtoyota from "../image/im-toyota-banner.png";

import { Col } from "react-bootstrap";
function App() {

  const location = useLocation();
  return (
    <>
      {" "}
      <Col xs="12" lg="4" className="mb-0 tab-content">
        {/* <img src={imtoyota} alt="logo" className="img-tab img-toyota-desktop " /> */}
        {/* <img
        src={imtoyotamobile}
        alt="logo"
        className="img-tab  img-toyota-mobile mt-2 mb-4"
      /> */}
      </Col>{" "}
    </>
  );
}
export default App;
