import {
    serviceUser,
    serviceReserveParking
} from "../Base"

export const RegisterService = async (
    vip_code,
    avatar,
    firstName,
    lastName,
    accept_term,
    plate_number,
    telephone,
    email,
    start_date,
    start_time,
    end_time,
    building,
    parking_lot,
    floor,
    language
) => {
    console.log(avatar)
    if (typeof avatar !== 'string' && avatar.length > 0) {
        let updateAvatar = new FormData()
        updateAvatar.append('file', avatar[0]);
        updateAvatar.append('code', vip_code);
        await serviceUser({
            method: 'post',
            url: '/customer/vipcode',
            data: updateAvatar
        })
    }

    let data = new FormData();
    data.append('first_name', firstName ?? "");
    data.append('last_name', lastName ?? "");
    data.append('telephone', telephone);
    data.append('email', email);
    data.append('vip_code', vip_code);
    data.append('accept_term', accept_term);
    data.append('social_type', "web");
    data.append('plate_number', plate_number);
    data.append("start_date", start_date)
    data.append("start_time", start_time)
    data.append("end_time", end_time)
    data.append('building', building);
    data.append('parking_lot', parking_lot);
    data.append('floor', floor);

    const responseData = await serviceReserveParking({
        method: 'post',
        url: '/reserve-parking-kiosk',
        headers: {
            'content-type': 'multipart/form-data',
            'language': language,
        },
        data: data
    }).then((response) => {
        return response.data
    }).catch(err => {
        return null
    })
    return responseData
}

export const VerifyVipCodeService = async (vip_code) => {
    const data = await serviceUser({
        method: 'get',
        url: `/vipcode?process_type=appointment&code=${vip_code}`
    })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            return null
        })
    return data
}


export const decodedVipCodeService = async (key) => {
    const data = await serviceUser({
        method: 'get',
        url: `/vipcode/decoded?key=${key}`

    })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            return null
        })
    return data
}


export const decodedVipDataService = async (key) => {
    const data = await serviceReserveParking({
        method: 'get',
        url: `/reserve-parking-vip?key=${key}`

    })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            return null
        })
    return data
}

export const reserveVipService = async (body, key) => {
    const data = await serviceReserveParking({
        method: 'patch',
        url: `/reserve-parking-vip`,
        data: body
    })
        .then((response) => {
            console.log(response.data)
            return response.data
        })
        .catch((err) => {
            return err?.response?.data
        })
    return data
}