import { useState, createContext, useEffect, useCallback } from "react";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useLanguage } from "../Components/translations/useLanguage";

export const GlobalContext = createContext()

const consentsDatas = [
    {
        heading: "Terms and Condition",
        accept: false,
        contents: [
            {
                sub_heading: "Terms",
                detail: `การใช้งานหรือแอพพลิเคชั่นของ Toyota Smart Office อยู่ภายใต้เงื่อนไขว่า ผู้ใช้งานต้องตกลงยอมรับข้อกำหนดและเงื่อนไขของข้อตกลงนี้
                โดยปราศจากการเปลี่ยนแปลงหรือยกเว้นใดๆ ที่แตกต่างไปจากที่ระบุไว้ในข้อตกลงนี้ ผู้ใช้งานไม่ถือว่าได้รับอนุญาต(ไม่ว่าทางตรงหรือทางอ้อม) 
                เว้นเสียแต่ว่า ผู้ใช้บริการได้แสดงเจตนา ตกลงยอมรัลข้อกำหนดและเงื่อนไขที่ระบุในข้อตกลงนี้ทุกประการก่อนเริ่มต้นการใช้งานหรือบริการ 
                หากผู้ใช้งานไม่ยอมรับข้อกำหนดหรือเงื่อนไขต่างๆเหล่านี้ โปรดอย่าเข้าสู่หรือใช้งานแอพพลิเคชั่นของToyota Smart Office`,
            },
            {
                sub_heading: "Condition",
                detail: `การใช้งานหรือแอพพลิเคชั่นของ Toyota Smart Office อยู่ภายใต้เงื่อนไขว่า ผู้ใช้งานต้องตกลงยอมรับข้อกำหนดและเงื่อนไขของข้อตกลงนี้
                โดยปราศจากการเปลี่ยนแปลงหรือยกเว้นใดๆ ที่แตกต่างไปจากที่ระบุไว้ในข้อตกลงนี้ ผู้ใช้งานไม่ถือว่าได้รับอนุญาต(ไม่ว่าทางตรงหรือทางอ้อม) 
                เว้นเสียแต่ว่า ผู้ใช้บริการได้แสดงเจตนา ตกลงยอมรัลข้อกำหนดและเงื่อนไขที่ระบุในข้อตกลงนี้ทุกประการก่อนเริ่มต้นการใช้งานหรือบริการ 
                หากผู้ใช้งานไม่ยอมรับข้อกำหนด หรือเงื่อนไขต่างๆเหล่านี้ โปรดอย่าเข้าสู่หรือใช้งานแอพพลิเคชั่นของToyota Smart Office`,
            }
        ],
        footer: "โปรดอ่านข้อกำหนดในการใช้งานนี้โดยละเอียดก่อนที่จะเข้าถึงหรือใช้เว็บไซต์ ซอฟต์แวร์ แอป และ/หรือ ปลั๊กอินที่ให้บริการโดย บริษัท โตโยต้า มอเตอร์ ประเทศไทย จำกัด หรือบริษัทในเครือ (เรียกแต่ละรายหรือเรียกรวมกันว่า \"ไซต์\")"
    },
    {
        heading: "Privacy Policy",
        accept: false,
        contents: [
            {
                sub_heading: "",
                detail: `การใช้งานหรือแอพพลิเคชั่นของ Toyota Smart Office อยู่ภายใต้เงื่อนไขว่า ผู้ใช้งานต้องตกลงยอมรับข้อกำหนดและเงื่อนไขของข้อตกลงนี้
                โดยปราศจากการเปลี่ยนแปลงหรือยกเว้นใดๆ ที่แตกต่างไปจากที่ระบุไว้ในข้อตกลงนี้ ผู้ใช้งานไม่ถือว่าได้รับอนุญาต(ไม่ว่าทางตรงหรือทางอ้อม) 
                เว้นเสียแต่ว่า ผู้ใช้บริการได้แสดงเจตนา ตกลงยอมรัลข้อกำหนดและเงื่อนไขที่ระบุในข้อตกลงนี้ทุกประการก่อนเริ่มต้นการใช้งานหรือบริการ 
                หากผู้ใช้งานไม่ยอมรับข้อกำหนดหรือเงื่อนไขต่างๆเหล่านี้ โปรดอย่าเข้าสู่หรือใช้งานแอพพลิเคชั่นของToyota Smart Office`,
            },
            {
                sub_heading: "",
                detail: `การใช้งานหรือแอพพลิเคชั่นของ Toyota Smart Office อยู่ภายใต้เงื่อนไขว่า ผู้ใช้งานต้องตกลงยอมรับข้อกำหนดและเงื่อนไขของข้อตกลงนี้ โดยปราศจากการเปลี่ยนแปลงหรือยกเว้นใดๆ ที่แตกต่างไปจากที่ระบุไว้ในข้อตกลงนี้ ผู้ใช้งานไม่ถือว่าได้รับอนุญาต(ไม่ว่าทางตรงหรือทางอ้อม) เว้นเสียแต่ว่า ผู้ใช้บริการได้แสดงเจตนา ตกลงยอมรัลข้อกำหนดและเงื่อนไขที่ระบุในข้อตกลงนี้ทุกประการก่อนเริ่มต้นการใช้งานหรือบริการ หากผู้ใช้งานไม่ยอมรับข้อกำหนด หรือเงื่อนไขต่างๆเหล่านี้ โปรดอย่าเข้าสู่หรือใช้งานแอพพลิเคชั่นของToyota Smart Office`,
            }
        ],
        footer: "โปรดอ่านข้อกำหนดในการใช้งานนี้โดยละเอียดก่อนที่จะเข้าถึงหรือใช้เว็บไซต์ ซอฟต์แวร์ แอป และ/หรือ ปลั๊กอินที่ให้บริการโดย บริษัท โตโยต้า มอเตอร์ ประเทศไทย จำกัด หรือบริษัทในเครือ (เรียกแต่ละรายหรือเรียกรวมกันว่า \"ไซต์\")"
    },
]

export const GlobalContextProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const { changeLanguage } = useLanguage()
    const currentDate = moment();
    const futureDate = currentDate.add(1, 'days');
    const [step, setStep] = useState(1)
    const [consents, setConsents] = useState(consentsDatas)
    const [qrcode, setQrcode] = useState([])
    const [parkings, setParkings] = useState([])
    const [i18nCurrentLanguage, i18nSetLang] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [initialForm] = useState({
        acceptTerm: false,
        privacy: false,
        vipCode: "",
        avatar: [],
        firstName: "",
        lastName: "",
        telephone: "",
        licensePlate: "",
        reserveDate: "",
        reserveStartTime: "",
        reserveEndTime: "",
        building: "TOYOTA ALIVE PLACE",
        parkingLot: "",
        floor: "",
        email: "",
        vipCodeCheck: "",
        startTimePicker: "",
        endTimePicker: "",
        avatarAPI: ""
    })
    const [reserve, setReserve] = useState(initialForm)

    const handleChangeReserve = useCallback((data) => {
        setReserve(data)
    }, [setReserve])

    const handleUpdateQrcode = useCallback((qrcode) => {
        setQrcode(qrcode)
    }, [setQrcode])

    const i18nChangeLanguage = async (lng) => {
        await changeLanguage(lng)
        await i18nSetLang(lng)
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        if (!i18nCurrentLanguage) {
            changeLanguage('en')
            i18nSetLang('en')
            i18n.changeLanguage('en');
        }
    }, [])

    return (
        <GlobalContext.Provider value={{
            handleChangeReserve,
            reserve,
            handleUpdateQrcode,
            qrcode,
            parkings, setParkings, initialForm, consents, setConsents,
            step, setStep, i18nChangeLanguage, i18nCurrentLanguage,
            isLoading, setIsLoading
        }}>
            {children}
        </GlobalContext.Provider>
    )
}