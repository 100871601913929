/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import { serviceReserveParking } from "../Services/Base"

export const useCarGuideLine = (floorProps, reserveDate, reserveStartTime, reserveEndTime, userType) => {
    const [floorLists, setFloors] = useState([])
    const [floor, selectedFloor] = useState(floorProps ? floorProps : 1)

    let url =
        userType ? `/car-guidances?member_type=${userType}`
            : `/car-guidances?member_type=customer&start_date=${reserveDate ?? ""}&start_time=${reserveStartTime}&end_time=${reserveEndTime}`
    useEffect(() => {
        if (floor === 1) {
            serviceReserveParking.get(url).then((response) => {
                const { floor_1 } = response.data.data
                const convertFromRaw = floor_1.map((item) => {
                    return {
                        ...item,
                        countUnconnected: floor_1.filter((lot) => lot.status === -1).length,
                        large: false
                    }
                })

                // map row 1
                const row1 = ['1-1', '1-2', '1-3', '1-4', '1-5', '1-6', '1-7', '1-8', '1-9', '1-10', '1-11', '1-12', '1-13']
                const row_1 = convertFromRaw.filter((item) => {
                    if (row1.includes(item.parking_lot)) {
                        return item
                    }
                })

                row_1.splice(5, 0, { name: 'stair' })
                row_1.splice(14, 0, { name: 'empty' })
                row_1.splice(15, 0, { name: 'empty' })
                row_1.splice(16, 0, { name: 'stair' })
                row_1.splice(17, 0, { name: 'liff' })
                row_1.splice(18, 0, { name: 'liff' })
                row_1.splice(19, 0, { name: 'empty' })

                // map row 2
                const row2 = ['1-14', '1-15', '1-16', '1-17', '1-18', '1-19', '1-20', '1-21', '1-22', '1-23', '1-24', '1-25', '1-26', '1-27', '1-28', '1-29']
                const row_2 = convertFromRaw.filter((item) => {
                    if (row2.includes(item.parking_lot)) {
                        return item
                    }
                })

                row_2.splice(1, 0, { name: 'empty' })
                row_2.splice(14, 0, { name: 'empty' })
                row_2.splice(15, 0, { name: 'empty' })

                const row3 = ['1-30', '1-31', '1-32', '1-33', '1-34', '1-35', '1-36', '1-37', '1-38', '1-39', '1-40', '1-41', '1-42', '1-43', '1-44', '1-45']
                const row_3 = convertFromRaw.filter((item) => {
                    if (row3.includes(item.parking_lot)) {
                        return item
                    }
                })


                row_3.splice(1, 0, { name: 'empty' })
                row_3.splice(14, 0, { name: 'empty' })
                row_3.splice(15, 0, { name: 'empty' })
                // map row 3


                const row4 = ['1-46', '1-48', '1-49', '1-50', '1-51', '1-52', '1-53', '1-54', '1-55', '1-56', '1-57', '1-58', '1-59', '1-60', '1-61', '1-62', '1-63']
                const row_4 = convertFromRaw.filter((item) => {
                    if (row4.includes(item.parking_lot)) {
                        return item
                    }
                })

                row_4.splice(1, 0, { name: 'empty' })
                row_4.splice(14, 0, { name: 'empty' })
                row_4.splice(15, 0, { name: 'empty' })

                const row5 = ['1-47', '1-65', '1-66', '1-67', '1-68', '1-69', '1-70', '1-71', '1-72', '1-73', '1-74', '1-75', '1-76', '1-77', '1-78', '1-79', '1-80']
                const row_5 = convertFromRaw.filter((item) => {
                    if (row5.includes(item.parking_lot)) {
                        return item
                    }
                })

                row_5.splice(1, 0, { name: 'empty' })
                row_5.splice(14, 0, { name: 'empty' })
                row_5.splice(15, 0, { name: 'empty' })

                const row6 = ['1-64', '1-81', '1-82', '1-83', '1-84', '1-85', '1-86']
                const row_6 = convertFromRaw.filter((item) => {
                    if (row6.includes(item.parking_lot)) {
                        return item
                    }
                })


                setFloors(
                    {
                        row1: row_1,
                        row2: row_2.map((item => {
                            return {
                                ...item,
                                large: item.parking_lot === '1-14'
                            }
                        })),
                        row3: row_3.map((item => {
                            return {
                                ...item,
                                large: item.parking_lot === '1-30'
                            }
                        })),
                        row4: row_4.map((item => {
                            return {
                                ...item,
                                large: item.parking_lot === '1-46'
                            }
                        })),
                        row5: row_5.map((item => {
                            return {
                                ...item,
                                large: item.parking_lot === '1-47'
                            }
                        })),
                        row6: row_6.map((item => {
                            return {
                                ...item,
                                large: item.parking_lot === '1-64'
                            }
                        })),
                    }
                )

            })
        }
    }, [floor])


    useEffect(() => {
        if (floor === 2) {
            serviceReserveParking.get(url).then((response) => {
                const { floor_2 } = response.data.data
                const convertFromRaw = floor_2.map((item) => {
                    return {
                        ...item,
                        imgPath:
                            item?.status === 1 ? "/available.svg" :
                                item?.status === 2 ? "/book.svg" :
                                    "/unavailable.svg"
                    }
                })

                // map row 1
                const row1 = ['2-1', '2-2', '2-3', '2-4', '2-5', '2-6', '2-7', '2-8', '2-9', '2-10', '2-11', '2-12', '2-13', '2-14', '2-15']
                const row_1 = convertFromRaw.filter((item) => {
                    if (row1.includes(item.parking_lot)) {
                        return item
                    }
                })
                row_1.splice(6, 0, { name: 'stair' })
                row_1.push({ name: 'empty' })
                row_1.push({ name: 'liff' })
                row_1.push({ name: 'liff' })

                // map row 2
                const row2 = ['2-16', '2-17', '2-18', '2-19', '2-20', '2-21', '2-22', '2-23', '2-24', '2-25', '2-26', '2-27', '2-28', '2-29',
                    // '2-30', '2-31'
                ]
                const row_2 = convertFromRaw.filter((item) => {
                    if (row2.includes(item.parking_lot)) {
                        return item
                    }
                })

                row_2.splice(1, 0, { name: 'empty' })

                // // map row 3
                const row3 = ['2-30', '2-31', '2-32', '2-33', '2-34', '2-35', '2-36', '2-37', '2-38', '2-39', '2-40', '2-41', '2-42', '2-43', '2-44', '2-45', '2-46']
                const row_3 = convertFromRaw.filter((item) => {
                    if (row3.includes(item.parking_lot)) {
                        return item
                    }
                })
                row_3.splice(1, 0, { name: 'empty' })
                row_3.splice(15, 0, { name: 'empty' })


                // // map row 4
                const row4 = [
                    '2-47',
                    '2-49',
                    '2-50',
                    '2-51',
                    '2-52',
                    '2-53',
                    '2-54',
                    '2-55',
                    '2-56',
                    '2-57',
                    '2-58',
                    '2-59',
                    '2-60',
                    '2-61',
                    '2-62',
                    '2-63',
                    '2-64',
                ]
                const row_4 = convertFromRaw.filter((item) => {
                    if (row4.includes(item.parking_lot)) {
                        return item
                    }
                })

                row_4.splice(1, 0, { name: 'empty' })
                row_4.splice(15, 0, { name: 'empty' })

                const row5 = [
                    '2-48',
                    '2-66',
                    '2-67',
                    '2-68',
                    '2-69',
                    '2-70',
                    '2-71',
                    '2-72',
                    '2-73',
                    '2-74',
                    '2-75',
                    '2-76',
                    '2-77',
                    '2-78',
                    '2-79',
                    '2-80',
                    '2-81',
                ]
                const row_5 = convertFromRaw.filter((item) => {
                    if (row5.includes(item.parking_lot)) {
                        return item
                    }
                })

                row_5.splice(1, 0, { name: 'empty' })
                row_5.splice(15, 0, { name: 'empty' })

                const row6 = [
                    '2-65',
                    '2-82',
                    '2-83',
                    '2-84',
                    '2-85',
                    '2-86',
                    '2-87',
                    '2-88',
                    '2-89'
                ]
                const row_6 = convertFromRaw.filter((item) => {
                    if (row6.includes(item.parking_lot)) {
                        return item
                    }
                })

                setFloors(
                    {
                        row1: row_1,
                        row2: row_2.map((item => {
                            return {
                                ...item,
                                large: item.parking_lot === '2-16'
                            }
                        })),
                        row3: row_3.map((item => {
                            return {
                                ...item,
                                large: item.parking_lot === '2-30'
                            }
                        })),
                        row4: row_4.map((item => {
                            return {
                                ...item,
                                large: item.parking_lot === '2-47'
                            }
                        })),
                        row5: row_5.map((item => {
                            return {
                                ...item,
                                large: item.parking_lot === '2-48'
                            }
                        })),
                        row6: row_6.map((item => {
                            return {
                                ...item,
                                large: item.parking_lot === '2-65'
                            }
                        })),
                    }
                )
            })
        }
    }, [floor])
    return {
        floorLists,
        floor, selectedFloor
    }
}
