import { Button, Col, Row, ToastContainer } from "react-bootstrap";
import Footer from "../Footer/Footer";
import TextField from "../Form/TextField";
import key from "../image/key.svg"
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const VerifyOtp = () => {
    const history = useHistory()
    const elem = useRef(null)
    const initialPin = {
        pinI: "",
        pinII: "",
        pinIII: "",
        pinIV: "",
        pinV: "",
        pinVI: "",
    }

    const [pin, setPin] = useState(
        initialPin
    )

    useEffect(() => {
        if (elem) {
            const findValueEmpty = [...elem.current.children]
            try {
                findValueEmpty[findValueEmpty.findIndex((item => item.value === ""))].focus()
            } catch (error) {

            }
        }
    }, [pin])

    const handleVerifySms = () => {
        history.push({
            pathname: "/parking/Register"
        })
        setPin(initialPin)
    }

    return (
        <Col xs="12" lg="8" className="text-center align-self-center">
            <ToastContainer />
            <Row className="justify-content-center">
                <Col xs="12" lg="10">
                    {" "}
                    <Row className="justify-content-center">
                        <Col xs="12" lg="12" className="text-center">
                            <h4 className=" fw-bold text-center">
                                Please enter the one time password <br />
                                to verify your account
                            </h4>
                        </Col>

                        <Col xs="12" lg="6" className="text-center my-5 d-flex bg-white">
                            <Row className="justify-content-center">
                                <Col xs="12" lg="12" className="justify-content-center d-flex flex-column pt-4">
                                    <div>
                                        <img src={key} alt="" />
                                    </div>
                                    <div className="mt-5">
                                        <h3 className=" fw-bold">
                                            A code has been to <br />
                                            <span className="text-light-blue">
                                                XXX-XXX4510
                                            </span>
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="12" lg="10" className="text-center my-5 d-flex" ref={elem}>
                                    <TextField className="mx-2 border p-3 rounded w-100" maxLength={1}
                                        value={pin.pinI}
                                        onKeyPressValidate={(text) => !/[0-9]/.test(text)}
                                        onChanged={(text) => setPin({
                                            ...pin,
                                            pinI: text
                                        })} />
                                    <TextField className="mx-2 border p-3 rounded w-100" maxLength={1}
                                        value={pin.pinII}
                                        onKeyPressValidate={(text) => !/[0-9]/.test(text)}
                                        onChanged={(text) => setPin({
                                            ...pin,
                                            pinII: text
                                        })} />
                                    <TextField className="mx-2 border p-3 rounded w-100" maxLength={1}
                                        value={pin.pinIII}
                                        onKeyPressValidate={(text) => !/[0-9]/.test(text)}
                                        onChanged={(text) => setPin({
                                            ...pin,
                                            pinIII: text
                                        })} />
                                    <TextField className="mx-2 border p-3 rounded w-100" maxLength={1}
                                        value={pin.pinIV}
                                        onKeyPressValidate={(text) => !/[0-9]/.test(text)}
                                        onChanged={(text) => setPin({
                                            ...pin,
                                            pinIV: text
                                        })} />
                                    <TextField className="mx-2 border p-3 rounded w-100" maxLength={1}
                                        value={pin.pinV}
                                        onKeyPressValidate={(text) => !/[0-9]/.test(text)}
                                        onChanged={(text) => setPin({
                                            ...pin,
                                            pinV: text
                                        })} />
                                    <TextField className="mx-2 border p-3 rounded w-100" maxLength={1}
                                        value={pin.pinVI}
                                        onKeyPressValidate={(text) => !/[0-9]/.test(text)}
                                        onChanged={(text) => setPin({
                                            ...pin,
                                            pinVI: text
                                        })} />
                                </Col>

                                <Col xs="12" lg="10" className="text-center mb-5 d-flex">
                                    <Button className="btn-danger px-4 btn-danger-size white-sp" onClick={() => handleVerifySms()}>
                                        Valify
                                    </Button>
                                </Col>

                            </Row>
                        </Col>
                    </Row>

                    <Col xs="12" lg="12" className="text-center mt-5">
                        <Footer />
                    </Col>
                </Col>
            </Row>
        </Col>
    );
}

export default VerifyOtp