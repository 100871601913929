// import Consentform from "../Pages/Consent_form";
// Register
// import QrRegister from "./Qr-Register";
// User-Register.js
// import UserRegister from "./User-Register";
import Tabcompany from "../Pages/tab_company";
// Download
// import PageDownload from "./Download";
// Test-Drive
// import PageTestDrive from "./Test-Drive";
// Regis-Testdrive
// import PageTestDriveRegis from "./Regis-Testdrive";
import { Row } from "react-bootstrap";
function App() {
  return (
    <>
      <Row className="justify-content-center">
        <Tabcompany />

        {/* <Consentform /> */}
        {/* <QrRegister /> */}
        {/* <UserRegister /> */}
        {/* <PageDownload /> */}
        {/* <PageTestDrive /> */}
        {/* <PageTestDriveRegis /> */}
      </Row>
    </>
  );
}

export default App;
