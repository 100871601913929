/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMediaQuery } from "react-responsive";
import { Button } from "react-bootstrap";
import { useLanguage } from "../translations/useLanguage";
const Header = () => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1025px)",
  });
  const { language, changeLanguage, translations } = useLanguage();
  return (
    <>
      {isDesktop === true ? (
        <div className="text-end mt-2 text-lang-postion">
          <a onClick={() => changeLanguage(language === "en" ? "th" : "en")}>
            <span
              className={
                language === "th" ? "fw-bold underline text-danger " : "fw-bold"
              }
            >
              ภาษาไทย
            </span>
          </a>
          <span> | </span>
          <a onClick={() => changeLanguage(language === "en" ? "th" : "en")}>
            <span
              className={
                language === "en" ? "fw-bold underline text-danger " : "fw-bold"
              }
            >
              English
            </span>
          </a>
        </div>
      ) : (
        <div className="text-end text-lang-postion ps-header-language-mobile">
          <Button
            className="btn-language-mobile "
            onClick={() => changeLanguage(language === "en" ? "th" : "en")}
          >
            <span className="fs-16 mg-n-5 fw-bold">
              {language === "en" ? "En" : "Th"}
            </span>
          </Button>
        </div>
      )}
    </>
  );
};

export default Header;
