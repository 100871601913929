import { useState, createContext } from "react";
// dataMock
import dataCar from "../Components/dataMock/dataCar";
export const SlideContext = createContext();
export const SlideContextProvider = ({ children }) => {
  const [dataSlide, setDataSlide] = useState([
    // { id: 0, grade: [] },
    // { id: 0, grade: [] },
  ]);
  //
  const [selectCar, setDataCar] = useState([]);
  const [sildeCars, setSildeCars] = useState([
    {
      id: 0,
      select: true,
      car: [{ cars: [] }],
      grade: [],
    },
    // {
    //   id: 1,
    //   select: true,
    //   car: [{ cars: [] }],
    //   grade: [],
    // },
  ]);
  const [dataReserveUser, setDataReserveUser] = useState({
    booking_type: "walkin",
    date: null,
    date_en: null,
    date_th: null,
    time: null,
    reserve: null,
    name: null,
    email: null,
    phone: "",
    TestDriveType: null,
    file: null,
  });
  const [activeSlide, setDataActive] = useState(false);

  const [activeIndex, setDataIndex] = useState(0);
  // setDataImg
  const [dataImg, setDataImg] = useState([]);
  const [typeCar, setDatatypeCar] = useState({
    idCard: 0,
    type: "Sedan & Hatchback",
  });
  const [activeCarSel, SelectCarDt] = useState([
    {
      card: 0,
      indexImg: 0,
      img: dataCar.carSedanHatchback[0].src,
      text: dataCar.carSedanHatchback[0].text,
      grade: dataCar.carSedanHatchback[0].grade[0],
      type: "Sedan & Hatchback",
      status: false,
    },
    {
      card: 1,
      indexImg: 0,
      img: dataCar.carSedanHatchback[0].src,
      text: dataCar.carSedanHatchback[0].text,
      grade: dataCar.carSedanHatchback[0].grade[0],
      type: "Sedan & Hatchback",
      status: false,
    },
  ]);
  const [dataSlideCar, setDataSlideCar] = useState([
    {
      id: 0,
      grade:
        typeCar.idCard === 0 && typeCar.type === "Commercial"
          ? dataCar.carCommercial[0].grade
          : typeCar.idCard === 0 && typeCar.type === "SUV & PPV"
          ? dataCar.carSUV[0].grade
          : dataCar.carSedanHatchback[0].grade,
      active: false,
    },
    {
      id: 0,
      grade:
        typeCar.idCard === 1 && typeCar.type === "Commercial"
          ? dataCar.carCommercial[0].grade
          : typeCar.idCard === 1 && typeCar.type === "SUV & PPV"
          ? dataCar.carSUV[0].grade
          : dataCar.carSedanHatchback[0].grade,
      active: false,
    },
  ]);
  const [actCarSlide, setCarActive] = useState({
    id: 0,
    data: {
      src: dataCar.carSedanHatchback[0].src,
      text: dataCar.carSedanHatchback[0].text,
      grade: dataCar.carSedanHatchback[0].grade[0],
      type: "Sedan & Hatchback",
    },
  });
  // imgIndex
  const [imgIndex, setImgIndex] = useState(0);
  const [cardIndex, setCardIndex] = useState(0);
  const [chKCard, setChKCard] = useState([
    { card: 0, idImg: 0, status: false },

    { card: 1, idImg: 0, status: false },
  ]);
  const [activeCancle, setDataActiveCancle] = useState([
    { status: false },
    { status: false },
  ]);
  return (
    <SlideContext.Provider
      value={{
        setDataSlide,
        dataSlide,
        // test
        setDataSlideCar,
        dataSlideCar,
        // active
        activeSlide,
        setDataActive,
        selectCar,
        setDataCar,
        // index
        activeIndex,
        setDataIndex,
        dataImg,
        setDataImg,

        // typeCar,
        // setDatatypeCar,

        actCarSlide,
        setCarActive,

        activeCarSel,
        SelectCarDt,
        // silde new
        sildeCars,
        setSildeCars,
        dataReserveUser,
        setDataReserveUser,
        // imgIndex, setImgIndex
        imgIndex,
        setImgIndex,
        cardIndex,
        setCardIndex,

        // test check card
        chKCard,
        setChKCard,
        // --
        activeCancle,
        setDataActiveCancle,
      }}
    >
      {children}
    </SlideContext.Provider>
  );
};
