const FileUpload = ({ name, className, multiple, accept, id, onChanged, type, value, onKeyPressValidate, maxLength, style, placeholder }) => {

    return (
        <input
            name={name}
            accept={accept}
            className={className}
            id={id}
            multiple={multiple}
            type={type}
            onChange={onChanged}
        />
    )
}

export default FileUpload