import React, { useEffect, useState } from "react";
import LoadingScreen from "react-loading-screen";
import komatsuGif from "../img-testDrive/toyota-car-load3.png";
import "./Loading.scss";
export default function App() {
  const [isLoding, setIsLoding] = useState(true);

  return (
    <div className="App">
      {isLoding ? (
        <>
          <LoadingScreen
            loading={true}
            bgColor="#49434333"
            // bgColor="transparent"
            spinnerColor="#e74c3c"
            textColor="#ff6353"
            // logoSrc={spinner}
            // logoSrc="https://i.gifer.com/7kvp.gif"
            logoSrc={komatsuGif}
            text="กำลังอัพเดตข้อมูล..."
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
}
