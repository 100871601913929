import { Button, Row, Col, Card } from "react-bootstrap";
import Select, { components } from "react-select";
import Footer from "../Footer/Footer"
import Calender from "./Calender";
import Form from "react-bootstrap/Form";
import UploadPicture from "./UploadPic-Regis";
import React, { useState, useEffect } from "react";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { useHistory, Redirect, Link } from "react-router-dom";
// noti
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
// picture
import { makeStyles } from "@material-ui/core/styles";
import ButtonPic from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconUpload from "../image/ic-upload.png";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));
function Test_DriveRegis() {
  let history = useHistory();
  const [file, setFile] = useState(false);

  const classes = useStyles();
  const dateFormat = "YYYY/MM/DD";
  const monthFormat = "YYYY/MM";

  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
  const [isChecked, setIsChecked] = useState(false);
  const customFormat = (value) => value.format(dateFormat);
  const optionsCar = [
    // Please Select

    { label: "Yaris 60th Anniversary", value: "Yaris" },
    { label: "Corolla Altis 60th Anniversary", value: "Corolla Altis" },
    { label: "Corolla Cross 60th Anniversary", value: "Corolla Cross " },
  ];
  const optionsTime = [
    // Please Select

    { label: "09.00-09.30", value: "09.00-09.30" },
    { label: "10.00-10.30", value: "10.00-10.30" },
    { label: "11.00-11.30", value: "11.00-11.30" },
  ];
  const [dataDate, setDataDatepicker] = useState(null);
  const [dataVehicle, setValueVehicle] = useState(null);
  const [dataTimingDrive, setValueTimingDrive] = useState(null);
  const [valueChkcondit, setDatacondit] = useState(false);
  const [valueChkDriveRise, setDataChkDriveRise] = useState(null);
  //   const [valueChkRise, setDataChkRise] = useState(false);
  //   SaveDataTestdrive
  const SaveDataTestdrive = () => {
    if (
      isChecked === false ||
      dataDate === null ||
      dataVehicle === null ||
      dataTimingDrive === null ||
      valueChkDriveRise === null ||
      file === false
    ) {
      notifyWarn();
    } else {
      notifySuccess();
      history.push({
        pathname: "/RegisQrTestdrive",
        search: "",
      });
      history.go({
        pathname: "/RegisQrTestdrive",
        search: "",
      });
    }

  };
  const handleChangepicture = (event) => {
    setFile(URL.createObjectURL(event.target.files[0]));
    // document.getElementById("abc").style.display = "flex";
  };
  const setDataDate = (e) => {
    var format_Date = moment(e).format("YYYY-MM-DD");
    setDataDatepicker(format_Date);
  };
  const handleChangeoption_Vehicle = (e) => {

    setValueVehicle(e.value);
  };
  const handleChangeoption_TimingDrive = (e) => {

    setValueTimingDrive(e.value);
  };
  const handleChange = (event) => {
    if (event.target.value === "true") {
      setDatacondit(true);
    }
  };
  //   getDataChkDrive
  const getDataChkDriveRise = (event) => {
    setDataChkDriveRise(event.target.value);
  };
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  //   noti
  const notifySuccess = () =>
    toast.success("กรอกข้อมูลสำเร็จ !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifyError = () => {
    toast.error("Wow serious error!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  const notifyWarn = () => {
    toast.warn("กรุณากรอกข้อมูลให้ครบถ้วนหรือกดยอมรับเงื่อนไขก่อนค่ะ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#f8ac59 !important",
      }),
      // progressClassName: css({
      //   background:
      //     "repeating-radial-gradient(circle at center, red 0, blue, green 30px)"
      // })
    });
  };
  return (
    <Col xs="12" lg="8" className="text-center align-self-center">
      <ToastContainer />
      <h3 className="card-text fw-bold">Register Test Drive</h3>
      <Row className="justify-content-center">
        <Col xs="12" lg="10">
          {" "}
          <Card className=" py-3 card-margin">
            <Card.Body className="">
              {/* <Row className="justify-content-center text-start px-5">
                <Col xs="12" lg="12">
                  <h5 className="fw-bold mb-0">
                    Please Select Series of Vehicle
                  </h5>
                </Col>
              </Row> */}

              <Row className="justify-content-center px-5 padding-testdrive">
                <Col xs="12" lg="12" className="text-start">
                  {" "}
                  <Row className="justify-content-center text-start ">
                    <Col xs="12" lg="12">
                      <p className="fw-bold mb-0">
                        Please Select Series of Vehicle
                      </p>
                    </Col>
                    <Col xs="12" lg="12">
                      <Select
                        options={optionsCar}
                        onChange={handleChangeoption_Vehicle}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-center text-start mt-2">
                    <Col xs="12" lg="6">
                      <p className="fw-bold mb-0">Date for Test Drive</p>
                      {/* <Calender /> */}
                      <DatePicker
                        onChange={(date) => setDataDate(date.$d)}
                        className=""
                        format={dateFormat}
                      />
                    </Col>
                    <Col xs="12" lg="6">
                      <p className="fw-bold mb-0">Timing for Test Drive</p>
                      <Select
                        options={optionsTime}
                        onChange={handleChangeoption_TimingDrive}
                        className="mt-1"
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-center text-start fw-bold mt-2">
                    <Col xs="12" lg="12">
                      Do you want to Drive or Ride?
                    </Col>
                  </Row>
                  <Form className="mt-2">
                    {[, "radio"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Drive"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                          onChange={getDataChkDriveRise}
                          value="Drive"
                        />
                        <Form.Check
                          className="left-radio-type2"
                          inline
                          label="Rise"
                          name="group1"
                          type={type}
                          id={`inline-${type}-2`}
                          onChange={getDataChkDriveRise}
                          value="Rise"
                        />
                      </div>
                    ))}
                  </Form>
                  {/* <Row className="justify-content-center text-start mt-2">
                    <Col xs="6" lg="6">
                      <Form.Check
                        type="radio"
                        label="Drive"
                        value="Drive"

                        
                      />
                    </Col>
                    <Col xs="6" lg="6">
                      <Form.Check type="radio" label="Ride" className="" />
                    </Col>
                  </Row> */}
                  <Row className="justify-content-center text-start">
                    <Col xs="12" lg="12">
                      <div className="form-check mt-2">
                        <input
                          //   onChange={handleChange}
                          checked={isChecked}
                          onChange={handleOnChange}
                          className="form-check-input"
                          type="checkbox"
                          value="true"
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          I’m Allow to Follow
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-1">
                    <Col xs="12" lg="12">
                      (If Drive) Photo License Driving Card/(If Ride) Photo ID
                      Card
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-3">
                    <Col xs="10" lg="7" className="text-center">
                      {" "}
                      <Card>
                        <Card.Body className="py-4">
                          {" "}
                          <Grid>
                            <Grid>
                              {/* logo */}
                              {(() => {
                                if (!file) {
                                  return (
                                    <img
                                      src={IconUpload}
                                      width="100px"
                                      height="auto"
                                      alt="IconUser"
                                    />
                                  );
                                } else {
                                  return (
                                    <img
                                      src={file}
                                      width="100%"
                                      height="auto"
                                      alt="imguser"
                                    />
                                  );
                                }
                              })()}

                              <p />
                              <input
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={handleChangepicture}
                              />
                              <label htmlFor="contained-button-file">
                                {/* color="primary" */}
                                <ButtonPic
                                  variant="contained"
                                  component="span"
                                  float="center"
                                  className="btn-upload py-1 px-2"
                                >
                                  Click to Upload
                                </ButtonPic>
                              </label>
                            </Grid>
                          </Grid>
                          {/* <UploadPicture /> */}
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-4">
                    <Col xs="6" lg="auto">
                      <Button className="btn-outline-danger btn-out-danger px-5">
                        Cancel
                      </Button>
                    </Col>
                    <Col xs="6" lg="auto">
                      {" "}
                      <Button
                        className="btn-danger px-5"
                        onClick={SaveDataTestdrive}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* <Row className="justify-content-center mt-3">
                <Col xs="12" lg="12">
                  {" "}
                  <Button className="btn-danger px-5">Done</Button>
                </Col>
              </Row> */}
            </Card.Body>
          </Card>{" "}
          {/*  footer */}
          <Row className="justify-content-center mt-5">
            <Footer />
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default Test_DriveRegis;
