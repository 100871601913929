import React, { useState, useContext, useEffect } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconUpload from "../image/ic-upload-new.png";
import ButtonPic from "@material-ui/core/Button";
import { UserData } from "../../Contexts/GlobalUser";
import { SlideContext } from "../../Contexts/CustomSlideContext";
import { useLanguage } from "./../translations/useLanguage";
import Loading from "../loading/Loading";
import Spinner from "react-bootstrap/Spinner";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

function UploadImage(props) {
  const { language, changeLanguage, translations } = useLanguage();
  const { dataUser } = useContext(UserData);
  const { dataReserveUser, setDataReserveUser } = useContext(SlideContext);
  const { setDataUser } = useContext(UserData);
  const [file, setFile] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleChange = (event) => {
    setFile(URL.createObjectURL(event.target.files[0]));
    document.getElementById("abc").style.display = "flex";
  };
  // test img
  const [originalWidth, setOriginalWidth] = useState(null);
  const [originalHeight, setOriginalHeight] = useState(null);

  const handleImageLoad = (event) => {
    setOriginalWidth(event.target.naturalWidth);
    setOriginalHeight(event.target.naturalHeight);
  };
  // ---
  const classes = useStyles();
  const handleChangepicture = async (e) => {
    try {
      setLoading(true);

      // Your file upload logic here
      // For example, you can use the FileReader API to read the file content
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const result = event.target.result;
        setDataUser({
          ...dataUser,
          file: file,
        });
        setFile(URL.createObjectURL(file));
        setDataReserveUser({
          ...dataReserveUser,
          file: URL.createObjectURL(file),
        });
      };

      reader.readAsDataURL(file);

      // Simulate a delay for demonstration purposes
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // After processing, reset loading state
      setLoading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoading(false);
    }
  };
  const handleChangepicture1 = async (event) => {
    await setLoading(true);
    setDataUser({
      ...dataUser,
      file: event.target.files[0],
    });
    if (URL) {
      await setLoading(false);
      setFile(URL.createObjectURL(event.target.files[0]));
    }
    // document.getElementById("abc").style.display = "flex";
  };

  useEffect(() => {
    // if (props.type1) {
    //   setFile(props.type1 === true ? false : true);
    // } else {
    if (props) {
      setFile(props.before !== props.after ? false : false);
    }

    // }
  }, [props.before, props.after]);

  return (
    <>
      {/* {isLoading ? <Loading /> : ""} */}
      <Card>
        {" "}
        <Card.Body className="py-4 text-center">
          {isLoading === true ? (
            <Spinner animation="border" variant="danger" />
          ) : (
            <Grid>
              <Grid>
                {/* logo */}

                {(() => {
                  if (file) {
                    return (
                      <img
                        src={file}
                        className={
                          originalWidth < 1000
                            ? "img-clickupload-1"
                            : "img-clickupload-2"
                        }
                        width="100%"
                        height="auto"
                        alt="imguser"
                        onLoad={handleImageLoad}
                      />
                    );
                  } else if (dataReserveUser && dataReserveUser.file) {
                    return (
                      <img
                        src={dataReserveUser.file}
                        width="100%"
                        height="auto"
                        className={
                          originalWidth < 1000
                            ? "img-clickupload-1"
                            : "img-clickupload-2"
                        }
                        alt="imguser"
                        onLoad={handleImageLoad}
                      />
                    );
                  } else {
                    return (
                      <img
                        src={IconUpload}
                        width="100px"
                        height="auto"
                        alt="IconUser"
                      />
                    );
                  }
                })()}
                {/* {originalWidth && originalHeight && (
                  <p>
                    Original size: {originalWidth} x {originalHeight}
                  </p>
                )} */}
                <p />
                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleChangepicture}
                />

                <label htmlFor="contained-button-file">
                  {/* color="primary" */}
                  <ButtonPic
                    // disabled={file}
                    variant="contained"
                    component="span"
                    float="center"
                    // className="btn-upload py-1 px-2"
                    className="btn-upload py-1 px-2"
                    // className={file ? "d-none" : "btn-upload py-1 px-2"}
                  >
                    {language === "en"
                      ? translations.text_Upload_EN
                      : translations.text_Upload_TH}
                  </ButtonPic>
                </label>
              </Grid>
            </Grid>
          )}

          {/* <UploadPicture /> */}
        </Card.Body>
      </Card>
    </>
  );
}
export default UploadImage;
