/* eslint-disable jsx-a11y/img-redundant-alt */
import { Row, Col } from "react-bootstrap";
import toyotaLogo from "../imgDownload/logo.png";
import imgAndroid from "../imgDownload/android.png";
import imgQrcode from "../imgDownload/qrcode.png";
import imgIos from "../imgDownload/ios.png";
import { useMediaQuery } from "react-responsive";
import "../Footer/footer.css";
const Footer = () => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  return (
    //
    <Row
      className={
        isDesktop === true
          ? "justify-content-start mt-2 footer-download py-2"
          : "d-none"
      }
    >
      <Col xs="11" lg="auto" className="wd-bg-footer">
        <div class="parent-footer">
          <div class="div1-footer align-self-center text-center">
            <img
              src={toyotaLogo}
              width={"60px"}
              height={"auto"}
              alt="Image"
              className=""
            />
          </div>
          <div class="div2-footer align-self-center text-start">
            <span className="fs-12 white-sp  fw-bold">TOYOTA ALIVE</span>{" "}
            <span className="fs-12  white-sp  fw-bold">
              ดาวน์โหลดได้แล้ววันนี้
            </span>
          </div>
          <div class="div3-footer align-self-center text-center">
            {" "}
            <img
              src={imgQrcode}
              width={"60px"}
              height={"auto"}
              alt="Image"
              className=" "
            />
          </div>
          <div class="div4-footer mt-1">
            {" "}
            <img
              src={imgAndroid}
              width={"100px"}
              height={"auto"}
              alt="Image"
              className=" "
              // image-container
            />
          </div>
          <div class="div5-footer">
            {" "}
            <img
              src={imgIos}
              width={"100px"}
              height={"auto"}
              alt="Image"
              className=" mt-ng-7"
            />
          </div>
        </div>
        {/* <Row className="justify-content-center ">
          <Col xs="4" lg="4" className="text-center align-self-center mg-rg-26">
            <img
              src={toyotaLogo}
              width={"60px"}
              height={"auto"}
              alt="Image"
              className="image-container cursor-p"
            />
           
          </Col>
          <Col xs="4" lg="4" className="text-center align-self-center ">
            <img
              src={imgAndroid}
              width={"100px"}
              height={"auto"}
              alt="Image"
              className="image-container cursor-p"
            />
          </Col>
          <Col xs="4" lg="4" className="text-center align-self-center ml-4">
            <img
              src={imgIos}
              width={"100px"}
              height={"auto"}
              alt="Image"
              className="image-container cursor-p"
            />
          </Col>
        </Row> */}
      </Col>
    </Row>

    // </Col>
  );
};

export default Footer;
