import { Button, Row, Col, Card } from "react-bootstrap";
import React, { useState } from "react";
import Iconandroid from "../image/ic-android.png";
import Iconios from "../image/ic-ios.png";
import Footer from "../Footer/Footer";
function Consent_form() {
  let base64Logo = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAA..";
  // const data = useState(base64Logo);
  const [data, setData] = useState(base64Logo);
  return (
    <Col xs="12" lg="8" className="text-center align-self-center">
      <Row className="justify-content-center">
        <Col xs="12" lg="7">
          {" "}
          <Card className=" py-4 card-margin ">
            <Card.Body>
              <Row className="justify-content-center">
                <h3>SMART OFFICE Application</h3>
                <Col xs="12" lg="12" className="mt-3">
                  <img src={Iconios} alt="" />
                </Col>
                <Col xs="12" lg="12" className="mt-3">
                  <img src={Iconandroid} alt="" />
                </Col>
              </Row>
            </Card.Body>
          </Card>{" "}
          {/*  footer */}
          <Row className="justify-content-center mt-5">
            <Footer/>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default Consent_form;
