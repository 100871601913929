import moment from "moment"

function generateStartTime(start, end) {
  const timeArray = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const timeString = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
      const timeObject = { label: timeString, value: timeString };
      if (hour < start || hour > end) {

      }
      else {
        timeArray.push(timeObject);
      }
    }
  }

  return timeArray;
}

export const startTime = generateStartTime(9.30, 10.30);
export const listEndTime = generateStartTime(10.00, 11.30);

export function generateEndTime(hourInput, minuteInput) {

  const startTime = moment(`2023-06-13 ${hourInput}:${minuteInput}`, 'YYYY-MM-DD HH:mm');

  const startDateList = listEndTime.map((item) => {
    const splitValue = item.value.split(":")
    return {
      hour: parseInt(splitValue[0]),
      minute: parseInt(splitValue[1]),
      label: item.label,
      value: item.value
    }
  })

  // const timeArray = startDateList.filter(item=> item.hour > hourInput)
  const timeArray = startDateList.filter(item => moment(`2023-06-13 ${item.hour}:${item.minute}`, 'YYYY-MM-DD HH:mm').isAfter(startTime))
  return timeArray;
}

function extractHHMM(text) {
  const pattern = /\b([01]?[0-9]|2[0-3]):([0-5][0-9])\b/g;
  const matches = text.match(pattern);
  return matches ? matches : [];
}

// export function generateTimeList(startTime, endTime) {
//   const timeList = [];

//   // Parse start and end times
//   const date = new Date();
//   const year = date.getFullYear();
//   const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
//   const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

//   const start = new Date(`${year}-${month}-${day}T${startTime}`);
//   const end = new Date(`${year}-${month}-${day}T${endTime}`);
//   start.setHours(start.getHours() - 1)
//   // Add 30 minutes to the end time
//   end.setMinutes(end.getMinutes() + 30);

//   // Set the interval for the loop (e.g., 30 minutes)
//   const interval = 30 * 60 * 1000; // 30 minutes in milliseconds

//   // Loop from the user-selected start time to the end time
//   for (let time = new Date(start); time < end; time.setTime(time.getTime() + interval)) {
//     const formattedTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
//     const dataFromRaw = extractHHMM(formattedTime)[0]
//     timeList.push(dataFromRaw);
//   }
//   timeList.pop()
//   return timeList;
// }
export function generateTimeList(startTime, endTime) {
  const timeList = [];

  // Parse start and end times
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  const start = new Date(`${year}-${month}-${day}T${startTime}`);
  const end = new Date(`${year}-${month}-${day}T${endTime}`);
  start.setHours(start.getHours() - 1);

  // Add 30 minutes to the end time
  end.setMinutes(end.getMinutes() + 30);

  // Set the interval for the loop (e.g., 30 minutes)
  const interval = 30 * 60 * 1000; // 30 minutes in milliseconds

  // Loop from the user-selected start time to the end time
  for (let time = new Date(start); time < end; time.setTime(time.getTime() + interval)) {
    const formattedTime = time.toTimeString().slice(0, 5); // Extract HH:MM
    const dataFromRaw = extractHHMM(formattedTime)[0];
    timeList.push(dataFromRaw);
  }
  timeList.pop(); // Remove the last element (endTime)
  return timeList;
}


// export function generateEndTimeList(selectedStartTime, endTime) {
//   const timeList = [];

//   // Parse user-selected start and end times
//   const start = new Date(`2023-01-01T${selectedStartTime}`);
//   const end = new Date(`2023-01-01T${endTime}`);

//   // Set the interval for the loop (e.g., 30 minutes)
//   const interval = 30 * 60 * 1000; // 30 minutes in milliseconds
//   start.setMinutes(start.getMinutes() + 30)
//   end.setMinutes(end.getMinutes() + 30)

//   // Loop from the user-selected start time to the end time
//   for (let time = new Date(start); time <= end; time.setTime(time.getTime() + interval)) {
//     const formattedTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
//     const dataFromRaw = extractHHMM(formattedTime)[0]
//     timeList.push(dataFromRaw);
//   }

//   return timeList;
// }

export function generateEndTimeList(selectedStartTime, endTime) {
  const timeList = [];

  // Parse user-selected start and end times
  const start = new Date(`2023-01-01T${selectedStartTime}`);
  const end = new Date(`2023-01-01T${endTime}`);

  // Set the interval for the loop (e.g., 30 minutes)
  const interval = 30 * 60 * 1000; // 30 minutes in milliseconds
  start.setMinutes(start.getMinutes() + 30);
  end.setMinutes(end.getMinutes() + 30);

  // Loop from the user-selected start time to the end time
  for (let time = new Date(start); time <= end; time.setTime(time.getTime() + interval)) {
    const formattedTime = time.toTimeString().slice(0, 5); // Extract HH:MM
    const dataFromRaw = extractHHMM(formattedTime)[0];
    timeList.push(dataFromRaw);
  }

  return timeList;
}

// Example usage
const userSelectedStartTime = '12:00';
const endTime = '17:30';

const timeList = generateTimeList(userSelectedStartTime, endTime);
console.log(timeList);
