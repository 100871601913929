import { useState, createContext } from "react";
import dataCar from "../Components/dataMock/dataCar";
export const CardsContext = createContext();
export const CardContextProvider = ({ children }) => {
  const [activeCard, setDataCard] = useState(0);
  const [activeBtn, setDataBtn] = useState(0);
  const [carsType, setDataCarType] = useState("");
  const [idData, setDataID] = useState("");
  //   setDataID
  return (
    <CardsContext.Provider
      value={{
        activeCard,
        setDataCard,
        activeBtn,
        setDataBtn,
        carsType,
        setDataCarType,
        idData,
        setDataID,
      }}
    >
      {children}
    </CardsContext.Provider>
  );
};
