import {
    serviceUser,
} from "../Base"

export const GetProfile = async () => {
    const data = await serviceUser({
        method: 'get',
        url: '/user/profile',
        headers: {
        "Authorization": `Bearer ${localStorage.getItem('access-token')}`
        }
    }).then((response) => {
        return response.data
    }).catch(err=> {
        console.log(err)
        return err.response
    })
    return data
}