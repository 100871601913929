import { useLocation } from 'react-router-dom';
import toyotaLogo from "./image/toyota-logo-new-1.png"
import { Col } from "react-bootstrap";
import FooterDownload from "../Components/Footer/DownloadFooter";
import { useMediaQuery } from "react-responsive";
function PanelLeftParking() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1025px)",
  });
  const location = useLocation();
  const currentPath = location.pathname;
  // "/Testdrive"
  const isHidden = ["",].find(item=> item === currentPath)
  return (
    <>
   {isDesktop===true?<>
   <Col xs="4" md="4" lg="4"  className="mb-0 tab-content img-toyota-desktop">
    </Col><FooterDownload />
    </>:<Col className='px-0 m-0 col-12' >
        <div className="img-toyota-mobile mb-4 ">
          <img src={toyotaLogo} alt="toyota" width={'80px'} />
        </div> 
      </Col> }
    
    {/* {
      !isHidden &&
      <Col className='px-0 m-0  col-auto' >
        <div className="img-toyota-mobile mb-4">
          <img src={toyotaLogo} alt="toyota" width={'80px'} />
        </div> <FooterDownload />
      </Col>   
    } */}
 </>
  )
}
export default PanelLeftParking
