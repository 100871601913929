const translations = {
  textTestDrive_TH: "ทดลองขับ",
  textToyota_TH: " TOYOTA ALIVE",
  text_at_TH: "ที่",
  text_chooseCar_TH: "เลือกรถ",
  text_numCar_TH: "รถคันที่",
  text_grade_TH: "เกรด",
  text_date_TH: "วันที่",
  text_remark: "*",
  text_datesub_TH: "โปรดเลือกวันที่",
  text_time_TH: "เวลา",
  text_timesub_TH: "โปรดเลือกเวลา",
  text_reserve_TH: "จองสำหรับ",
  text_reserve_for_TH: "สำหรับตัวเอง",
  text_reserve_other_TH: "สำหรับผู้อื่น",
  text_next_TH: "ถัดไป",
  text_condition_TH:
    "สงวนลิขสิทธิ์ 2564 บริษัท โตโยต้า มอเตอร์ ประเทศไทย จำกัด เงื่อนไขการใช้งาน นโยบายความเป็นส่วนตัว นโยบายการใช้คุกกี้",
  //  step 2
  textPersonal_TH: "ข้อมูลส่วนบุคคล",
  text_perName_TH: "ชื่อ-นามสกุล",
  text_perPhone_TH: "หมายเลขโทรศัพท์",
  text_perEmail_TH: "อีเมล",
  text_Driver_TH: "ขับเอง",
  text_Passenger_TH: "ผู้โดยสาร",
  text_condit_License_TH:
    "(กรณีเลือกขับเอง) แนบรูปใบขับขี่ / (กรณีเลือกผู้โดยสาร) แนปรูปบัตรประชาชน",
  text_LicenseCard_TH: "แนบรูปใบขับขี่",
  text_IDCard_TH: "แนบรูปบัตรประชาชน",
  text_Upload_TH: "คลิกเพื่ออัปโหลด",
  text_consent_TH:
    "ข้าพเจ้าอนุญาตให้ TMT เก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล เพื่อวัตถุประสงค์ทางการตลาดและการติดต่อสื่อสาร",
  text_regis_TH: "ลงทะเบียน",
  text_back_TH: "ย้อนกลับ",
  text_close_TH: "ปิด",
  text_errorEmail_TH: "กรุณากรอกรูปแบบอีเมลให้ถูกต้อง",
  text_errorPhone_TH: "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง",
  // step3
  text_TestDriveType_TH: "ประเภทการทดลองขับ",
  text_regissuc_TH: "ลงทะเบียนสำเร็จ",
  text_Location_TH: "สถานที่",
  text_Booking_TH: "รหัสการจอง",
  text_qrdetail_TH:
    "กรุณาแสดง QR Code ให้เจ้าหน้าที่ เพื่อยืนยันการทดลองขับของคุณ",
  text_alertSuccessCar_TH:
    "กรุณามาถึงอย่างน้อย 15 นาทีก่อนเวลาจอง และกรุณาสวมรองเท้าผ้าใบหรือหุ้มส้นในวันทดลองขับ",
  text_direct_TH: "ขอเส้นทาง",
  // alert noti
  text_errorDate_TH: "โปรดเลือกวันที่",
  text_errorTime_TH: "โปรดเลือกเวลา",
  text_errorReserve_TH: "โปรดเลือกการจองสำหรับ",
  text_errorFillname_TH: "กรุณากรอกชื่อและนามสกุล",
  text_errorPhone_TH: "กรุณากรอกเบอร์โทรศัพท์",
  // export data
  bookingtext_TH: "การจองทดลองขับของคุณ",
  bookingid_TH: "รหัสการจอง: ",
  content_TH: "แสดง QR Code ให้เจ้าหน้าที่เพื่อยืนยันการทดลองขับของคุณ",
  typetitle_TH: "ประเภท",
  locationtitle_TH: "สถานที่",
  remark_TH: "*",
  description_TH:
    "กรุณามาถึงอย่างน้อย 15 นาทีก่อนเวลาจอง และกรุณาสวมรองเท้าผ้าใบหรือหุ้มส้นในวันทดลองขับ",
  downloadtext_TH: "ดาวน์โหลดได้แล้ววันนี้",
  maintext_TH: "TOYOTA ALIVE",
  maptext_TH: "แผนที่ Toyota Alive",
};

export default translations;
