const translations = {
  textTestDrive_EN: "Test Drive",
  textToyota_EN: "TOYOTA ALIVE",
  text_at_EN: "at ",
  text_chooseCar_EN: "Choose car",
  text_numCar_EN: "Test Drive Car",
  text_grade_EN: "Grade",
  text_date_EN: "Date",
  text_remark: "*",
  text_datesub_EN: "Please select Date",
  text_time_EN: "Time",
  text_timesub_EN: "Please select Time",
  text_reserve_EN: "Reserve for",
  text_reserve_for_EN: "for yourself",
  text_reserve_other_EN: "for others",
  text_condition_EN:
    "Copyright 2022 Toyota Motor Thailand Co., Ltd. All rights reserved. Terms of Use Privacy Policy Cookies Policy",
  text_next_EN: "Next",
  // step 2
  textPersonal_EN: "Personal Information",
  text_perName_EN: "Name - Surname",
  text_perPhone_EN: "Phone Number",
  text_perEmail_EN: "Email",
  text_TestDriveType_EN: "Test Drive Type",
  text_Driver_EN: "Driver",
  text_Passenger_EN: "Passenger",
  text_condit_License_EN:
    "(If Driver) Attach Driving License Card Photo / (If Passenger) Attach Citizen ID Card Photo",
  text_LicenseCard_EN: "Attach Driving License Card Photo",
  text_IDCard_EN: "Attach Citizen ID Card Photo",
  text_Upload_EN: "Click to upload",
  text_consent_EN:
    "I consent TMT to collect, use, and disclose my personal data for the purposes of marketing activities and communicate.",
  text_regis_EN: "Register",
  text_back_EN: "Back",
  text_close_EN: "Close",
  text_errorEmail_EN: "Please fill a valid email",
  text_errorPhone_EN: "Please fill a valid phone number",
  // step3
  text_regissuc_EN: "Register Success",
  text_Location_EN: "Location",
  text_Booking_EN: "Booking ID",
  text_qrdetail_EN:
    "Please show the QR Code to the staff for test drive confirmation.",
  text_alertSuccessCar_EN:
    "*Please arrive at least 15 minutes before your reservation time. And please wear sneakers or covered heels on the test drive day.",
  text_direct_EN: "Get Direction",
  // alert noti
  text_errorDate_EN: "Please select Date",
  text_errorTime_EN: "Please select Time",
  text_errorReserve_EN: "Please select Reserve for",
  text_errorFillname_EN: "Please fill name - surname.",
  text_errorPhone_EN: "Please fill phone number.",
  // export data
  bookingtext_EN: "Your Test Drive Reservation",
  bookingid_EN: "Booking ID: ",
  content_EN: "Please show the QR Code to the staff for test drive confirmation.",
  typetitle_EN: "Test Drive Type",
  locationtitle_EN: "Location",
  remark_EN: "*",
  description_EN:
    "Please arrive at least 15 minutes before your reservation time. And please wear sneakers or covered heels on the test drive day.",
  downloadtext_EN: "DOWNLOAD NOW!",
  maintext_EN: "TOYOTA ALIVE",
  maptext_EN: "Get Direction",
};

export default translations;
