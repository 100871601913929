import "./App.css";
import "./Components/css/testDrive.css";
import "antd/dist/reset.css";
import "bootstrap/dist/css/bootstrap.min.css";
import './assets/fonts/font.css';
import Index from "./Components/Pages/index";
import { Container } from "react-bootstrap";
import Admin from "./Components/Pages/Admin";
import Tabcompany from "./Components/Pages/tab_company";
import PanelLeftParking from "./Components/panelLeftParking";
import QrRegister from "./Components/Pages/QrRegister/Qr-Register";
// User-Register.js
import RegisterParkingVip from "./Components/Pages/RegisterParkingVip";
import UserRegister from "./Components/Pages/User-Register";
// Download
import PageDownload from "./Components/Pages/Download";
// Test-Drive
import PageTestDrive from "./Components/Pages/Test-Drive";
// Regis-Testdrive
import PageTestDriveRegis from "./Components/Pages/Regis-Testdrive";
// Qr-Testdrive
import PageQrTestdrive from "./Components/Pages/Qr-Testdrive";
// testPage
import PageTest from "./Components/Pages/TestPage";
import PageExportData from "./Components/CaptureData/dataExport";
import Consentform from "./Components/Pages/Consent_form";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import VerifyOtp from "./Components/Pages/verify-otp";
// slidercontext
import { SlideContextProvider } from "./Contexts/CustomSlideContext";
// user
import { UserContextProvider } from "./Contexts/GlobalUser";
// card
// slidercontext
import { CardContextProvider } from "./Contexts/CardContext";
// 2 lang
import { LanguageProvider } from "./Components/translations/useLanguage";
import YourComponent from "./Components/translations/YourComponent";
import ParkingLot from "./Components/Pages/ParkingLot";
import Translate from "./Components/TranslateComponent/Translate";
import ParkingLotReadOnly from "./Components/Parking/ParkingLot/ReadOnly/ParkingLot"
import "./locales/i18n";
export const Context = React.createContext();
function App() {
  const [context, setContext] = useState("default context value");
  return (
    <>
      <Context.Provider value={[context, setContext]}>
        <Router basename="/landing">
          {/* <AnimatePresence exitBeforeEnter> */}{" "}
          <SwitchTransition>
            <CSSTransition timeout={450} classNames="fade">
              <Switch>
                <Route path="/admin">
                  <Container>
                    <Row className="justify-content-center">
                      <Admin />
                    </Row>
                  </Container>
                </Route>
                <Route path="/parking/select-lot">
                  <Container fluid>
                    <Row>
                      <Translate />
                      <PanelLeftParking />
                      <ParkingLot />
                    </Row>
                  </Container>
                </Route>
                <Route path="/parking/parking-lot">
                  <Container fluid>
                    <Row>
                      <Translate />
                      <PanelLeftParking />
                      <Col xs="12" lg="8" className="mx-auto">
                        <ParkingLotReadOnly />
                      </Col>
                    </Row>
                  </Container>
                </Route>
                <Route path="/parking/RegisterVip">
                  <Container fluid>
                    <Row>
                      <Translate />
                      <PanelLeftParking />
                      <RegisterParkingVip />
                    </Row>
                  </Container>
                </Route>
                <Route path="/parking/Register">
                  <Container fluid>
                    <Row>
                      <Translate />
                      <PanelLeftParking />
                      <UserRegister />
                    </Row>
                  </Container>
                </Route>

                <Route path="/verify-otp">
                  <Container>
                    <Row className="justify-content-center">
                      <Tabcompany />
                      <VerifyOtp />
                    </Row>
                  </Container>
                </Route>
                <Route path="/parking/qrRegister">
                  <Container>
                    <Row className="justify-content-center">
                      <PanelLeftParking />
                      <QrRegister />
                    </Row>
                  </Container>
                </Route>
                <Route path="/Download">
                  <Container>
                    <Row className="justify-content-center">
                      <Tabcompany />
                      <PageDownload />
                    </Row>
                  </Container>
                </Route>
                <Route path="/parking/">
                  <Container fluid>
                    <Row>
                      <Translate />
                      <PanelLeftParking />
                      <Consentform />
                    </Row>
                  </Container>
                </Route>
                <Route path="/TestDrivebyWalk-in">
                  {/* <Container> */}
                  <SlideContextProvider>
                    <UserContextProvider>
                      <CardContextProvider>
                        <LanguageProvider>
                          <YourComponent />

                          <Row className="justify-content-center">
                            {/* <Tabcompany /> */}
                            <PanelLeftParking />
                            <PageTestDrive />
                          </Row>
                        </LanguageProvider>
                      </CardContextProvider>
                    </UserContextProvider>
                  </SlideContextProvider>
                  {/* </Container> */}
                </Route>
                {/* /RegisTestdrive */}
                <Route path="/RegisTestdrive">
                  <Container>
                    <Row className="justify-content-center">
                      <Tabcompany />
                      <PageTestDriveRegis />
                    </Row>
                  </Container>
                </Route>
                {/* PageQrTestdrive */}
                <Route path="/RegisQrTestdrive">
                  <Container>
                    <Row className="justify-content-center">
                      <Tabcompany />
                      <PageQrTestdrive />
                    </Row>
                  </Container>
                </Route>

                <Route path="/PageTestdrive">
                  <Row className="justify-content-center">
                    <Tabcompany />
                    <PageTest />
                  </Row>
                </Route>
                {/* PageExportData */}
                <Route path="/PageExportData">
                  <Row className="justify-content-center">
                    <PageExportData />
                  </Row>
                </Route>
                <Redirect to="/parking" />
              </Switch>
            </CSSTransition>
          </SwitchTransition>
          {/* </AnimatePresence> */}
        </Router>
      </Context.Provider>
    </>
  );
}

export default App;
