import { serviceTestDrive } from "../Base";
import Moment from "moment";
const Service_ClientId =
  "5b57fb2da870b8b3d1fc39f6b10391a0259733c69403617c563afb18b9c6d3ad";

export const postTestDrive = async (
  dataBody,
  dataReserve,
  dataUser,
  datalanguage
) => {
  let dataTest = {
    booking_type: "walkin",
    testdrive_date: "2024-03-03",
    reserve_type: "self",
    car_id1: 17,
    car_id2: "",
    testdrive_type: "",
    fullname: "",
    firstname: "Athittaya Pleeklam",
    lastname: "",
    phone: "0968955216",
    email: "oei28768@gmail.com",
    dateofbirth: "",
    gender: "",
    testdrive_time: "10:30 - 11:00",
    permission_tmt: false,
    permission_dealer: false,
    file: "",
  };
  let data = new FormData();

  // -----------
  data.append("booking_type", dataReserve.booking_type ?? "");
  // date_en
  data.append(
    "testdrive_date",
    Moment(new Date(dataReserve.date_en)).format("YYYY-MM-DD") ?? ""
  );
  data.append("reserve_type", dataReserve.reserve);
  data.append("car_id1", dataBody[0].indexCar);
  data.append("car_id2", dataBody.length > 1 ? dataBody[1].indexCar : "");
  data.append(
    "testdrive_type",
    datalanguage === "en"
      ? dataUser.testdrive_type
      : dataUser.testdrive_type === "ผู้โดยสาร"
      ? "Passenger"
      : "Driver"
  );
  // console.log("dataReserve.email : ", typeof dataReserve.email);
  data.append("fullname", dataReserve.name);
  data.append("phone", dataReserve.phone);
  data.append("email", dataReserve.email === null ? "" : dataReserve.email);
  // data.append(
  //   "email",
  //   dataReserve.email === null || typeof dataReserve.email === "object"
  //     ? null
  //     : dataReserve.email
  // );
  data.append("dateofbirth", dataUser.dateofbirth);
  data.append("gender", dataUser.gender);
  data.append("testdrive_time", dataReserve.time);
  data.append("permission_tmt", dataUser.permission_tmt === true ? 1 : 0);
  data.append("file", dataUser.file);
  // ------------
  // data.append("booking_type", dataBody.booking_type ?? "");
  // data.append("testdrive_date", dataBody.testdrive_date ?? "");
  // data.append("reserve_type", dataBody.reserve_type);
  // for (let [key, value] of data.entries()) {
  //   console.log("key : " + key, typeof value, " : " + value);
  // }
  const respon = await serviceTestDrive({
    method: "post",
    url: `/testdrive/reserve`,
    headers: {
      clientid: Service_ClientId,
      "content-type": "multipart/form-data",
      language: datalanguage,
    },
    data: data,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      console.log("respon data err : ", err.response.data);
      return err.response.data;
    });
  return respon;
};
