import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import ConstentItem from "../../Components/ConstentItem"
function Consent_form() {

  return (
    <Col xl="8" className="text-center align-self-center">
      <Row className="justify-content-center">
        <Col xs="12" lg="10">
          <ConstentItem />
        </Col>
      </Row>
    </Col>
  );
}

export default Consent_form;
