import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, {
  useRef,
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { SlideContext } from "../../Contexts/CustomSlideContext";
import { useMediaQuery } from "react-responsive";
import { useLanguage } from "../translations/useLanguage";

const ImageSlider = ({
  images,
  cardNum,
  dataCar,
  type,
  dataRow,
  card,
  dataTestDrive,
}) => {
  const { language } = useLanguage();
  const isDesktop = useMediaQuery({
    query: "(min-width: 1025px)",
  });
  const [startIndex, setStartIndex] = useState(0);
  const [newIndex, setNewIndex] = useState(0);

  const [selectedSlide, setSelectedSlide] = useState(null);
  const sliderRef = useRef();
  const {
    setDataSlide,
    setDataSlideCar,
    setDataActive,
    setDataIndex,
    setCarActive,
    SelectCarDt,
    setImgIndex,
    setCardIndex,
    setChKCard,
    // new
    sildeCars,
    setSildeCars,
  } = useContext(SlideContext);
  const [dataCarTestDrive, setDataCarTestDrive] = useState(null);
  const handleSlideChange = (index) => {
    // setCurrentSlide(index);
  };

  const handleGetImg = (index) => {
    setImgIndex(index);
    setDataIndex(index);
    // images.splice(0);
    // setDataSlide(data);
  };
  const settings = {
    // accessibility: true,
    // initialSlide: 0,
    // key: startIndex,
    infinite: true,
    slidesToShow:
      sildeCars && sildeCars[cardNum].car.length > 3
        ? isDesktop
          ? 3
          : 1
        : sildeCars && sildeCars[cardNum].car.length === 3
        ? 1
        : 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: false,
    autoplaySpeed: 1500,
    centerMode: true,
    afterChange: handleSlideChange,
    centerPadding: "0",
    nextArrow: (
      <div>
        <div className={isDesktop ? "next-slick-arrow" : "next-slick-arrow"}>
          <i className="fa fa-angle-right" aria-hidden="true" />
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className={isDesktop ? "prev-slick-arrow" : "prev-slick-arrow"}>
          <i className="fa fa-angle-left" aria-hidden="true" />
        </div>
      </div>
    ),
    // test---------
    afterChange: (index) => {
      setCurrentSlide(index);
      // console.log("Current slide index:", index);
    },
  };

  const beforeSlideChange = useCallback((oldIndex, newIndex) => {
    setDataIndex(newIndex);

    // setImgIndex(newIndex);
    // setImgIndex(0);

    setStartIndex(newIndex);
  }, []);
  const handleCarouselChange1 = (newIndex) => {
    // console.log("newIndex", newIndex);
  };
  const handleCarouselChange = (newIndex) => {
    localStorage.removeItem("storedDataCar");
    setSelectedSlide(images[newIndex]);
    setStoredData(null);
    // setImgIndex(0);
    // if (images.length < newIndex) {
    //   setImgIndex(newIndex);
    // } else {
    //   setNewIndex(newIndex);
    // }
    //  else {
    //   setImgIndex(images.length - 1);
    // }

    setStartIndex(newIndex);
    SelectCarDt((prevData) => {
      const obj = [...prevData];
      obj[cardNum] = {
        card: cardNum,
        indexImg: images[newIndex].id,
        slideImg: newIndex,
        img: images[newIndex].src,
        text: images[newIndex].text,
        grade: images[newIndex].grade[0],
        type: images[newIndex].type,
        carValue: dataCar,
      };
      return obj;
    });
    setCardIndex(cardNum);
    setDataActive(true);
    setChKCard((prevData) => {
      const obj = [...prevData];
      obj[cardNum] = {
        card: cardNum,
        idImg: newIndex,
        status: true,
      };
      return obj;
    });
    const filterImages = images.filter((data, i) => {
      return newIndex === i;
    });
    setCarActive({ id: cardNum, data: images[newIndex] });
    setDataIndex(newIndex);
    setDataSlide(filterImages[0].grade);
    // setDataSlideCar((prevData) => {
    //   const obj = [...prevData];
    //   obj[cardNum] = {
    //     id: cardNum,
    //     index: newIndex,
    //     value: filterImages[0].text,
    //     grade: images[newIndex].grade,
    //     active: cardNum ? true : false,
    //     src: filterImages[0].src,
    //   };
    //   return obj;
    // });

    const foundData = dataRow.carValue.find(
      (item) => item.data.type_en === dataRow.type
    );
    setDataSlideCar((prevData) => {
      const obj = [...prevData];
      obj[cardNum] = {
        id: cardNum,
        index: newIndex,
        slideImg: newIndex,
        value: filterImages[0].text,
        grade: foundData
          ? foundData.data.dataCar[newIndex].cars.map((item) => ({
              grade: item.car_grade,
              id: item.car_id,
            }))
          : [{ grade: "AA", id: "AA" }],
        active: cardNum ? true : false,
        src: filterImages[0].src,
      };
      return obj;
    });

    setSildeCars((prevData) => {
      const obj = [...prevData];
      obj[cardNum] = {
        grade: foundData.data.dataCar[newIndex].cars[0].car_grade,
        id: cardNum,
        select: true,
        car: foundData.data.dataCar,
        indexCar: foundData.data.dataCar[newIndex].cars[0].car_id,
        // src: foundData.data.dataCar[newIndex].image_url,
        // type: foundData.data.type_en,
        text: {
          th: foundData.data.dataCar[newIndex].model_name_th,
          en: foundData.data.dataCar[newIndex].model_name_en,
        },

        // select new
        label: foundData.data.dataCar[newIndex].cars[0].car_grade,
        value: foundData.data.dataCar[newIndex].cars[0].car_id,
        src: foundData.data.dataCar[newIndex].image_url,
        type: foundData.data.type_en,
        type_th: foundData.data.type_th,
        type_en: foundData.data.type_en,
      };
      return obj;
    });
    // ----------------------
  };

  const [storedData, setStoredData] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    // const data = localStorage.getItem("storedDataCar");
    // setStoredData(data);

    if (localStorage.getItem("storedDataCar") && !storedData) {
      const storedData = localStorage.getItem("storedDataCar");
      const indexImgData = JSON.parse(storedData);
      setStoredData({
        src: indexImgData[0].img,
        text: indexImgData[0].text,
      });
    } else {
      setStoredData(null);
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount
  useEffect(
    () => {
      setDataCarTestDrive(sildeCars);
    },
    [storedData, dataCarTestDrive]
    // [isCheckedTmt, isCheckedDealer]
  );

  return (
    <>
      <div className="imgslider mt-3" id="sliderimg">
        <Slider
          ref={sliderRef}
          {...settings}
          beforeChange={beforeSlideChange}
          afterChange={handleCarouselChange}
        >
          {sildeCars &&
            sildeCars[cardNum].car.map((item, index) => (
              <div key={index}>
                <img
                  key={index}
                  src={item.image_url}
                  // src={storedData && index === 0 ? storedData.src : item.src}
                  alt={`Slide ${index}`}
                  width="100%"
                  onClick={() => handleGetImg(index)}
                />

                <label
                  className={
                    isDesktop === true ? "fs-16 fw-bold" : "fs-16 fw-bold"
                  }
                >
                  {language === "th" ? item.model_name_th : item.model_name_en}
                </label>
              </div>
            ))}
        </Slider>
      </div>
    </>
  );
};
export default ImageSlider;
