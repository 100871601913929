/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Row, Col, Card, ProgressBar } from "react-bootstrap";
import { GlobalContext } from "../Contexts/GlobalConext"
import { useState, useRef, useContext, useEffect } from "react"
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";
import { getPdpa } from "../Services/TermAndPrivacy/TermAndPrivacy"
import { convertWebviewToRaw } from "../helpers/webViewToRawHtmlHelper"
import { useTranslation } from 'react-i18next';
import Loading from "../Components/loading/Loading"
import Footer from "../Components/Footer/Footer";

const ConstentItem = () => {
    const { consents, setConsents, handleChangeReserve, reserve, i18nChangeLanguage, i18nCurrentLanguage } = useContext(GlobalContext)
    const { t } = useTranslation();
    let history = useHistory();
    const [pdpa, setPdpa] = useState({})
    const [cust_type, setType] = useState('')
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const param = searchParams.get("uuid");
    const type = searchParams.get("customer_type");

    useEffect(() => {
        fetchingPdpa()
        setType(type)
    }, [i18nCurrentLanguage])

    const fetchingPdpa = async () => {
        try {
            const pdpa = await getPdpa(i18nCurrentLanguage)
            const terms = await convertWebviewToRaw(pdpa.term_and_condition)
            const privacy = await convertWebviewToRaw(pdpa.privacy)

            setPdpa({
                term: terms,
                privacy: privacy
            })
        } catch (error) {

        }
    }

    const stepDic = {
        termAndCondition: 0,
        privacyPolicy: 1
    }
    const [currentStep, setStep] = useState(0)
    const [scroll, setScroll] = useState(0)
    const [isDisabled, setisDisabled] = useState(true);
    const containerContent = useRef(null)

    const handleSubmit = () => {
        handleChangeReserve({
            ...reserve,
            acceptTerm: consents[stepDic.termAndCondition].accept,
            privacy: consents[stepDic.privacyPolicy].accept,
        })
        if (param) {
            if (cust_type?.toLowerCase() === 'vip') {
                history.push({
                    pathname: `/parking/RegisterVip`,
                    search: `?uuid=${param}`
                });
            } else {
                history.push({
                    pathname: `/parking/Register`,
                    search: `?uuid=${param}`
                });
            }
        } else {
            history.push({
                pathname: `/parking/Register`,
            });
        }
    }

    useEffect(() => {
        const handleScroll = (event) => {
            try {
                if (event.currentTarget.scrollTop) {
                    setScroll(event.currentTarget.scrollTop)
                }
                if (Math.floor((containerContent.current.scrollHeight - event.currentTarget.scrollTop) - 50) <= (containerContent.current.clientHeight)) {
                    setisDisabled(false)
                } else {
                    setisDisabled(true)
                }
            } catch (error) {
                setisDisabled(true)
            }
        };

        if (containerContent.current) {
            containerContent.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (containerContent.current) {
                containerContent.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <>
            <Row className="justify-content-center mt-1">
                <h3 className="card-text mb-5 fw-bold">{t('consent_parking')}</h3>
                <Row className="mx-auto bg-white pb-3  w-100 d-flex justify-content-center radius-35 p-0">
                    <Col
                        xs="10"
                        lg="10"
                        className="text-start scroll-condition-type-1"
                        ref={containerContent}
                    >
                        {
                            pdpa?.term &&
                            <div className="pb-3">
                                <h4>{t('term_parking')}</h4>
                            </div>
                        }

                        <div dangerouslySetInnerHTML={{ __html: pdpa.term }} />
                        {
                            pdpa?.privacy &&
                            <div className="pb-3 pt-5">
                                <h4>{t('privacy_parking')}</h4>
                            </div>
                        }
                        <div dangerouslySetInnerHTML={{ __html: pdpa.privacy }} />
                    </Col>
                    <Row className="justify-content-center mt-3">
                        <Col xs="auto" lg="auto">
                            <div className="form-check my-3">
                                <input
                                    checked={consents[currentStep].accept}
                                    onChange={(event) => {
                                        const findUpdate = [...consents]
                                        findUpdate[currentStep].accept = event.target.checked
                                        setConsents(findUpdate)
                                    }
                                    }
                                    className="form-check-input"
                                    type="checkbox"
                                    value="step1"
                                    id="flexCheckDefault"
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                >
                                    {t('detail_consent_parking')}
                                </label>
                            </div>
                            <Button className="btn-danger px-5"
                                onClick={() => handleSubmit()}
                                disabled={
                                    isDisabled ||
                                    (!consents[currentStep].accept)
                                }>
                                {t('next')}
                            </Button>
                        </Col>
                    </Row>{" "}
                </Row>
                {/*  footer */}
                <div className="mt-3">
                    <Footer />
                </div>
            </Row>
        </>
    )
}

export default ConstentItem