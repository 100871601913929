import { useContext } from "react"
import "./step.css"
import { GlobalContext } from "../../Contexts/GlobalConext"
const ChooseStep = ({ debug, stepLists }) => {
    const { step } = useContext(GlobalContext)
    return (
        <div className="container-step-parking">
            <section className="d-flex  align-items-center">
                <div className="container-fluid">
                    <div className="steps-pc">
                        <div className="line"></div>
                        {
                            stepLists?.map((item, index) => {
                                return (
                                    <div className="step-item justify-content-between align-items-center" key={index}>
                                        <div className={`step-button d-flex aling-items-center justify-content-center text-center ${step == index + 1 ? "active" : step > index + 1 ? "passed" : ""}`}>
                                            <div className=" d-flex align-items-center justify-content-center ">
                                                {index + 1}
                                            </div>
                                        </div>
                                        {
                                            item?.name &&
                                            <div className={`px-2 flex-wrap  step-title  ${step == index + 1 ? "text-active" : step > index + 1 ? "text-active" : ""}`}>
                                                {item?.name}
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section >
        </div>
    )
}

export default ChooseStep