import { Button, Row, Col, Card } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRcode from "react-qr-code";
import React, { useState } from "react";
import Iconsuccess from "../image/ic-success.png";
import { useHistory, Redirect, Link } from "react-router-dom";
import Footer from "../Footer/Footer";
function QR_form() {
  let base64Logo = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAA..";
  // const data = useState(base64Logo);
  let history = useHistory();
  const [data, setData] = useState(base64Logo);
  const handleClick = () => {
    history.push({
      pathname: "/",
      search: "",
    });
    history.go({
      pathname: "/",
      search: "",
    });
  };

  return (
    <Col xs="12" lg="8" className="text-center align-self-center">
      <h3 className="card-text fw-bold">Test Drive</h3>
      <Row className="justify-content-center mt-5">
        <Col xs="12" lg="10">
          {" "}
          <Card className=" py-0 card-margin">
            <Card.Body>
              <Row className="justify-content-center">
                <Col xs="12" lg="12">
                  <img src={Iconsuccess} className="mt-3" alt="qr" />
                  {/* <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /> */}

                  <h3 className="mt-2">Request Completed</h3>
                  <p>
                    Testdrive Vehical Camry with Instructor Vinai Jaithean On
                    Thursday 31May 2022 from 15:00 - 16:00 at Toyota Smart
                    Office Building TDEX)A1)
                  </p>
                  <QRcode value={base64Logo} />
                </Col>
              </Row>
              <Row className="justify-content-center mt-3">
                <Col xs="12" lg="12">
                  {" "}
                  <Button className="btn-danger px-5" onClick={handleClick}>
                    Close
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>{" "}
          {/*  footer */}
          <Row className="justify-content-center mt-5">
            <Footer />
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default QR_form;
