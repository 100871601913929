import { Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useLanguage } from "./../translations/useLanguage";
const Footer = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isHidden = ["/parking", "/parking/Register"].find(
    (item) => item === currentPath
  );
  const { language, changeLanguage, translations } = useLanguage();
  return (
    <Col
      xs="11"
      lg="auto"
      className={isHidden ? "hidden-element-in-mobile" : "mt-3 mb-4"}
    >
      <span>
        {language === "en"
          ? translations.text_condition_EN
          : translations.text_condition_TH}
      </span>
      {/* <br />
      <span className="mb-0">
        เงื่อนไขการใช้งาน | นโยบายความเป็นส่วนตัว | นโยบายการใช้คุกกี้
      </span> */}
    </Col>
  );
};

export default Footer;
