import { serviceUser } from "../Base";

export const getTermandConData = async (dataType, datalanguage) => {
  const data = await serviceUser({
    method: "get",
    url: `/auth/terms?process=` + dataType,
    headers: {
      language: datalanguage,
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return null;
    });
  return data;
};
