import { Col } from "react-bootstrap"
import ParkingLots from "../Parking/ParkingLot/ParkingLots"

const ParkingLot = () => {
    return (
        <Col xs="12" lg="8" className="mx-auto">
            <ParkingLots />
        </Col>
    )
}

export default ParkingLot