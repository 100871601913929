import { Button, Row, Col, Card } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRcode from "react-qr-code";
import Iconsuccess from "../image/ic-success.png";
import { useHistory, Redirect, Link } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";

import Footer from "../Footer/Footer";
import { GlobalContext } from "../../Contexts/GlobalConext";
import TextField from "../Form/TextField";
import { ToastContainer, toast } from "react-toastify";
import { ToastManagementContext } from "../../Contexts/ToastManagementContext";
import {
  BallTriangle,
  Hearts,
  Rings,
  TailSpin,
  ThreeDots,
  Puff,
  Oval,
  Grid,
} from "react-loader-spinner";
function QR_form() {
  const { notifySuccess, notifyWarn, notifyError } = useContext(
    ToastManagementContext
  );
  let base64Logo = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAA..";
  // const data = useState(base64Logo);
  let history = useHistory();
  const { qrcode } = useContext(GlobalContext);

  const [data, setData] = useState({
    dataCode: "",
    dataUsername: "",
    dataName: "",
    dataSurName: "",
    dataTel: "",
    dataLicense: "",
    dataTimestart: "",
    dataTimeend: "",
    dataDate: "",
    valueBuilding: "",
    valueParking: "",
    dataEmail: "",
  });
  const handleClick = () => {
    console.log("data : ", data);

    if (
      data.dataCode === "" ||
      data.dataUsername === "" ||
      data.dataName === "" ||
      data.dataSurName === "" ||
      data.dataTel === "" ||
      data.dataEmail === ""
    ) {
      notifyWarn("กรุณากรอกข้อมูลให้ครบถ้วน !");
    } else {
      notifySuccess();
    }

    // notifyError();
    // history.push({
    //   pathname: "/Download",
    //   search: "",
    // });
    // history.go({
    //   pathname: "/Download",
    //   search: "",
    // });
  };
  return (
    <Col xs="12" lg="8" className="text-center align-self-center">
      <h3 className="card-text fw-bold">
        Register Parking
        <span className="text-danger "> Toyota Building (TDEX)</span>
      </h3>
      <Row className="justify-content-center">
        <Col xs="12" lg="12">
          <Rings color="#00BFFF" height={280} width={280} />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="12" lg="10">
          {" "}
          <Card className=" py-0 card-margin">
            <Card.Body>
              <Row className="justify-content-center">
                <h5 className="text-center fw-bold">Register data</h5>
                {/* text-start */}
                {/* <Col xs="2" lg="2" className="text-center align-self-center">
                  <h7 className=" fw-bold">Code Vip </h7>
                </Col> */}
                <Col xs="12" lg="12">
                  <p className="text-start fw-bold">Code Vip </p>
                  <TextField
                    type="text"
                    name="dataCode"
                    className="form-control position-input-code"
                    onChanged={(text) => setData({ ...data, dataCode: text })}
                    value={data.dataCode}
                    maxLength={9}
                  />
                </Col>
                <Col xs="12" lg="12">
                  <p className="text-start fw-bold mt-2">Username </p>
                  <TextField
                    type="text"
                    name="dataCode"
                    className="form-control position-input-code"
                    onChanged={(text) =>
                      setData({ ...data, dataUsername: text })
                    }
                    value={data.dataUsername}
                    maxLength={9}
                  />
                </Col>
                <Col xs="12" lg="12">
                  <p className="text-start fw-bold mt-2">Name-Surname</p>
                  <TextField
                    type="text"
                    name="dataCode"
                    className="form-control position-input-code"
                    onChanged={(text) => setData({ ...data, dataName: text })}
                    value={data.dataName}
                  />
                </Col>
                <Col xs="12" lg="12">
                  <p className="text-start fw-bold mt-2">Tel</p>
                  <TextField
                    type="number"
                    name="dataCode"
                    className="form-control position-input-code"
                    onChanged={(text) => setData({ ...data, dataTel: text })}
                    value={data.dataTel}
                    maxLength={10}
                  />
                </Col>
                <Col xs="12" lg="12">
                  <p className="text-start fw-bold mt-2">Email</p>
                  <TextField
                    type="email"
                    name="dataCode"
                    className="form-control position-input-code"
                    onChanged={(text) => setData({ ...data, dataEmail: text })}
                    value={data.dataEmail}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center mt-3">
                <Col xs="12" lg="12">
                  {" "}
                  <Button
                    className="btn-danger px-5"
                    onClick={() => handleClick()}
                  >
                    Done
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>{" "}
          {/*  footer */}
          {/* <Row className="justify-content-center ">
            <Footer />
          </Row> */}
        </Col>
      </Row>
    </Col>
  );
}

export default QR_form;
