import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconUpload from "../image/ic-upload.png";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export default function Trial() {
  const [file, setFile] = useState(false);
  const handleChange = (event) => {
    setFile(URL.createObjectURL(event.target.files[0]));
    document.getElementById("abc").style.display = "flex";
  };
  const classes = useStyles();

  return (
    <>
      <Grid>
        <Grid>
          {/* logo */}
          {(() => {
            if (!file) {
              return (
                <img
                  src={IconUpload}
                  width="100px"
                  height="auto"
                  alt="IconUser"
                />
              );
            } else {
              return (
                <img src={file} width="100%" height="auto" alt="imguser" />
              );
            }
          })()}

          <p />
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleChange}
          />
          <label htmlFor="contained-button-file">
            {/* color="primary" */}
            <Button
              variant="contained"
              component="span"
              float="center"
              className="btn-upload py-1 px-2"
            >
              Click to Upload
            </Button>
          </label>
        </Grid>
      </Grid>

      {/* <input type="file" onChange={handleChange}/>
     <img id="abc" src={file} height='80px' style={{display: "none"}}/> 
    */}
    </>
  );
}
