import { serviceTestDrive } from "../Base";
const Service_ClientId =
  "5b57fb2da870b8b3d1fc39f6b10391a0259733c69403617c563afb18b9c6d3ad";
// process.env.REACT_APP_ClientId;

export const getCarDataInfo = async (datalanguage) => {
  // https://tsmartoffice.verkoffice.com/testdrive/info
  const data = await serviceTestDrive({
    method: "get",
    url: `/testdrive/info`,
    headers: {
      clientid: Service_ClientId,
      language: datalanguage,
    },
  })
    .then((response) => {
      if (response) {
        return response.data.data;
      } else {
        return null;
      }
    })
    .catch((err) => {
      return null;
    });
  return data;
};
