import { Button, Row, Col, Spinner } from "react-bootstrap";
import { GlobalContext } from "../../Contexts/GlobalConext"
import { ToastManagementContext } from "../../Contexts/ToastManagementContext"
import { decodedVipDataService, reserveVipService } from "../../Services/Auth"
import TextField from "../Form/TextField";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "../Footer/Footer";
import { useHistory, useLocation } from "react-router-dom";
import ParkingTopPanel from "../Parking/ParkingTopPanel/ParkingTopPanel"
import { useTranslation } from "react-i18next"
import Loading from '../loading/Loading'

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: "none",
    },
}));

function RegisterParkingVip() {
    const { t } = useTranslation()
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const param = searchParams.get("uuid");
    const { handleChangeReserve, reserve, setStep, initialForm } = useContext(GlobalContext)
    const { notifyError } = useContext(ToastManagementContext)
    const [isLoading, setIsLoading] = useState(false)
    // datepicker
    const [file, setFile] = useState(null);
    const handleChange = (event) => {
        try {
            if (event.target) {
                setFile(URL.createObjectURL(event.target.files[0]));
                handleChangeReserve({
                    ...reserve,
                    avatar: event.target.files
                })
            } else if (event.length > 0) {
                setFile(URL.createObjectURL(event[0]));
            } else {
                setFile(reserve.avatarAPI)
            }
        } catch (error) {

        }
    };

    const classes = useStyles();

    const handleSelectedFloor = (e) => {
        handleChangeReserve({
            ...reserve,
            floor: e
        })
    };
    let history = useHistory();

    //   ClearData
    const ClearData = () => {
        handleChangeReserve(initialForm)
    };

    const fetchData = async () => {
        const response = await decodedVipDataService(param)
        console.log(response)
        if (response) {
            console.log(response.data)

            handleChangeReserve({
                ...reserve,
                telephone: response?.data?.telephone,
                building: response?.data?.building ?? "TOYOTA ALIVE PLACE",
                email: response?.data?.email,
                reserveDate: moment(response?.data?.start_date).format("YYYY-MM-DD"),
                reserveStartTime: response?.data?.start_time,
                reserveEndTime: response?.data?.end_time,
                licensePlate: response?.data?.plate_number,
                parkingLot: response?.data?.parking_lot,
                floor: response?.data?.floor
            })
        }
    }


    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            const response = await reserveVipService(
                {
                    key: param,
                    telephone: reserve?.telephone,
                    building: reserve?.building,
                    email: reserve?.email,
                    plate_number: reserve.licensePlate
                },
            )
            if (response.status === 'success') {
                setIsLoading(false)
                localStorage.setItem('reserve_parking', JSON.stringify({
                    reserve: { ...reserve, qrNumber: response?.data?.qr_number }
                }))
                history.push({
                    pathname: `/parking/qrRegister`,
                });
            }
            notifyError()
            setIsLoading(false)
        } catch (error) {
            notifyError()
            setIsLoading(false)
            console.log('found error')
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    if (isLoading) {
        return <Loading />
    }
    return (
        <Col xs="12" lg="8" className="text-center align-self-center position-relative overflow-hidden">
            <ToastContainer />
            <div className="mb-5">
                <ParkingTopPanel building={reserve?.building} />
            </div>
            <Row className="justify-content-center">
                <Col xs="12" lg="10" className="bg-white p-4 radius-35">
                    <Row className="justify-content-center">
                        <Col xs="12" lg="9" className="text-start">
                            {" "}
                            <Row className="justify-content-center text-start">
                                <Col xs="12" lg="23" className="mt-3">
                                    <Row className="d-flex mb-2 align-items-center">
                                        <Col xs="12" lg="12">
                                            <p className="fw-bold mb-0 white-sp">
                                                {t('phone_parking')}<span className="text-danger ">*</span>
                                            </p>
                                        </Col>
                                        <Col xs="12" lg="12">
                                            <TextField name="dataTel"
                                                value={reserve.telephone}
                                                maxLength={10}
                                                onChanged={(text) => handleChangeReserve({
                                                    ...reserve,
                                                    telephone: text
                                                })}
                                                onKeyPressValidate={(text) => !/[0-9]/.test(text)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs="12" lg="12" className="mt-3">
                                    <Row className="d-flex align-items-center mb-2">
                                        <Col xs="12" lg="12">
                                            <p className="fw-bold mb-0">
                                                {t('email_parking')}<span className="text-danger ">*</span>
                                            </p>
                                        </Col>
                                        <Col xs="12" lg="12">
                                            <TextField name="dataTel"
                                                value={reserve.email}
                                                onChanged={(text) => handleChangeReserve({
                                                    ...reserve,
                                                    email: text
                                                })}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>


                            <Row className="justify-content-center text-start mb-2 d-flex align-items-center mt-3">
                                <Col xs="12" lg="12" className="white-sp">
                                    <Col>
                                        <p className="fw-bold mb-0"> {t('license_plate_parking')}<span className="text-danger ">*</span></p>
                                    </Col>
                                </Col>
                                <Col xs="12" lg="12" className="">
                                    <TextField
                                        name="dataLicense"
                                        placeholder={t('forms.parking.placeholder_license_plate')}
                                        value={reserve.licensePlate}
                                        onKeyPressValidate={(text) => !/[A-Za-zก-๙0-9]/.test(text)}
                                        onChanged={(text) => handleChangeReserve({
                                            ...reserve,
                                            licensePlate: text
                                        })}
                                    />
                                </Col>
                            </Row>

                            <Col xs="12" lg="12">
                                <p className="text-danger">
                                    <i>
                                        * {t('time_warn')}
                                    </i>
                                </p>
                            </Col>
                            <Row className="justify-content-center my-4">
                                <Col xs="6" lg="auto" className="d-flex justify-content-center">
                                    <Button
                                        className="mt-2 btn-outline-danger btn-out-danger px-5"
                                        onClick={() => ClearData()}
                                    >
                                        {t('cancel')}
                                    </Button>
                                </Col>
                                <Col xs="6" lg="auto" className="d-flex justify-content-center">
                                    <Button
                                        className="mt-2 btn-danger px-5"
                                        type="button"
                                        disabled={
                                            !reserve.telephone ||
                                            !reserve.email ||
                                            !reserve.licensePlate
                                        }
                                        onClick={() => handleSubmit()}
                                    >
                                        {t('next')}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/*  footer */}
                </Col>
                <Row className="justify-content-center mt-3">
                    <Footer />
                </Row>
            </Row >
        </Col >
    );
}

export default RegisterParkingVip;
